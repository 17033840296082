export const rules = {
    name: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    status_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    gender_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    nation_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    last_name: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
};
