<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.staff')"
      filterable
      clearable
      :multiple="multiple"
      :size="size"
      :class="mode ? 'input__day' : 'input__night'"
      class="d-block"
    >
      <el-option
        v-for="(staff, index) in items"
        :key="'staffs-' + index"
        :label="staff.name + ' ' + staff.last_name"
        :value="staff.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  name: "staff",
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    filter: {
      type: Object,
      required: false,
    },
    excepted_id: {
      type: Number,
      default: null,
      required: false,
    },
  },
  watch: {
    id: {
      handler: function () {
        this.selected = this.id;
      },
      immediate: true,
    },
    excepted_id: {
      handler: function () {},
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.staffs) this.fetchStaffs();
  },
  computed: {
    ...mapGetters({
      staffs: "staff/telegramStaff",
      mode:'MODE'
    }),
    items: function () {
      let excepted_id = this.excepted_id;
      return !this.excepted_id
        ? this.staffs
        : _.filter(this.staffs, function (staff) {
            return staff.id != excepted_id;
          });
    },
  },
  methods: {
    ...mapActions({
      updateInventory: "staff/telegramStaff",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
    fetchStaffs() {
      const query = { ...this.filter };
      this.updateInventory(query)
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
  },
};
</script>
