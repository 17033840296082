export const mutations = {
    SET_ZKTECO_USER: (state, zkteco_user) => {              
        state.zkteco_user = zkteco_user; 
        state.createZktecoUser = zkteco_user ? true : false;       
    },
    SET_ZKTECO_DEVICES: (state, company_device_ids) => {              
        state.company_device_ids=company_device_ids
    },
    SET_CREATE_ZKTECO_USER:(state,boolValue)=>{
        state.createZktecoUser=boolValue;
    },
    SET_ZKTECO_LOCATION: (state, location_ids) => {              
      state.location_ids=location_ids
  },
};
