import request from '@/utils/request'

export function getStatistics(params) {   
  return request({
      url: '/dashboard/statistics',
      method: 'get',
      params: params      
  })
}
export function getNewComeStaffs(params) {   
  return request({
      url: '/dashboard/get-new-come-staffs',
      method: 'get',
      params: params      
  })
}
export function getCompleteStaffProfile(params) {   
  return request({
      url: '/dashboard/get-complete-staff-profile',
      method: 'get',
      params: params      
  })
}
export function getStaffAgeStatistic(params) {   
  return request({
      url: '/dashboard/staff-age-statistic',
      method: 'get',
      params: params      
  })
}
export function getStaffUpdate(params) {   
  return request({
      url: '/dashboard/staff-update',
      method: 'get',
      params: params      
  })
}
export function getStaffGenderStatistic(params) {   
  return request({
      url: '/dashboard/staff-gender-statistic',
      method: 'get',
      params: params      
  })
}
export function getTodayBirthdays(params) {   
  return request({
      url: '/dashboard/today-birthdays',
      method: 'get',
      params: params      
  })
}
export function getExpectedBirthdays(params) {   
  return request({
      url: '/dashboard/expected-birthdays',
      method: 'get',
      params: params      
  })
}
export function getPastBirthdays(params) {   
  return request({
      url: '/dashboard/past-birthdays',
      method: 'get',
      params: params      
  })
}
export function getStaffByEducation(params) {   
  return request({
      url: '/dashboard/staff-by-education',
      method: 'get',
      params: params      
  })
}
export function getStaffByReason(params) {   
  return request({
      url: '/dashboard/staff-by-reason',
      method: 'get',
      params: params      
  })
}
export function getStaffWorkType(params) {   
  return request({
      url: '/dashboard/staff-work-type',
      method: 'get',
      params: params      
  })
}
export function getWorkExperience(params) {   
  return request({
      url: '/dashboard/work-experience',
      method: 'get',
      params: params      
  })
}
export function getCompleteProfileTheFiliale(params) {   
  return request({
      url: '/dashboard/complete-profile-the-filiale',
      method: 'get',
      params: params      
  })
}
export function getEmployeesByRegion(params) {   
  return request({
      url: '/dashboard/employees-by-region',
      method: 'get',
      params: params      
  })
}
export function getStaffFromCandidate(params) {   
  return request({
      url: '/dashboard/staff-from-candidate',
      method: 'get',
      params: params      
  })
}
export function getCandidate(params) {   
  return request({
      url: '/dashboard/candidate',
      method: 'get',
      params: params      
  })
}
export function getReleaseAndReception(params) {   
  return request({
      url: '/dashboard/release-and-reception',
      method: 'get',
      params: params      
  })
}
export function getSource(params) {   
  return request({
      url: '/dashboard/source',
      method: 'get',
      params: params      
  })
}
export function getWages(params) {   
  return request({
      url: '/dashboard/wages',
      method: 'get',
      params: params      
  })
}
export function getAverageSalaryByBranches(params) {   
  return request({
      url: '/dashboard/average-salary-by-branches',
      method: 'get',
      params: params      
  })
}
export function getStaffAttendences(params) {   
  return request({
      url: '/dashboard/staff-attendences',
      method: 'get',
      params: params      
  })
}
export function getFaceIdRegistration(params) {   
  return request({
      url: '/dashboard/face-id-registration',
      method: 'get',
      params: params      
  })
}
export function getAttendStaffWeek(params) {   
  return request({
      url: '/dashboard/attand-staff-weekly',
      method: 'get',
      params: params      
  })
}

export function getSaffTelegramInformation(params) {   
  return request({
      url: '/dashboard/staff/telegram-information',
      method: 'get',
      params: params      
  })
}

export function getVisitors(params) {   
  return request({
      url: '/dashboard/visitors',
      method: 'get',
      params: params      
  })
}
export function getTopManagers(params) {   
  return request({
      url: '/dashboard/top-managers',
      method: 'get',
      params: params      
  })
}
export function getEmployeeKpp(params) {   
  return request({
      url: '/dashboard/employee-kpp-statistic',
      method: 'get',
      params: params      
  })
}
export function getTransportKpp(params) {   
  return request({
      url: '/dashboard/transport-statistic',
      method: 'get',
      params: params      
  })
}
export function getReasonByOperation(params) {   
  return request({
      url: '/dashboard/operation-by-reasons',
      method: 'get',
      params: params      
  })
}

export function getTmsStatistic(params) {   
  return request({
      url: '/dashboard/tms-statistic',
      method: 'get',
      params: params      
  })
}
export function getTmsList(params) {   
  return request({
      url: '/dashboard/tms-last-list',
      method: 'get',
      params: params      
  })
}
export function getTopToiletAttendants(params) {   
  return request({
      url: '/dashboard/most-go-to-toilet',
      method: 'get',
      params: params      
  })
}
export function getStaffWithTotalWork(params) {   
  return request({
      url: '/dashboard/top-the-best-staffs',
      method: 'get',
      params: params      
  })
}
export function getHasSalaryStaffs(params) {   
  return request({
      url: '/dashboard/has-salary-statistic',
      method: 'get',
      params: params      
  })
}
export function getRequestsForToday(params) {   
  return request({
      url: '/dashboard/requests-for-today',
      method: 'get',
      params: params      
  })
}
export function getStaffByMood(params) {   
  return request({
      url: '/dashboard/staffs/get-by-mood',
      method: 'get',
      params: params      
  })
}
export function getSituationForToday(params) {   
  return request({
      url: '/dashboard/situation-for-today',
      method: 'get',
      params: params      
  })
}
