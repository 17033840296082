import {
  i18n
} from '@/utils/i18n';

export const columns = {
  id: {
    show: true,
    title: i18n.t('message.n'),
    sortable: true,
    column: 'id'
  },
  name: {
    show: true,
    title: i18n.t('message.nameFull'),
    sortable: true,
    column: 'name'
  },
  last_name: {
    show: true,
    title: i18n.t('message.last_name'),
    sortable: true,
    column: 'last_name'
  },
  middle_name: {
    show: false,
    title: i18n.t('message.middle_name'),
    sortable: true,
    column: 'middle_name'
  },
  date_of_birth: {
    show: false,
    title: i18n.t('message.date_of_birth'),
    sortable: true,
    column: 'date_of_birth'
  },
  gender_id: {
    show: true,
    title: i18n.t('message.gender'),
    sortable: true,
    column: 'gender_id'
  },
  phone_number: {
    show: false,
    title: i18n.t('message.phone_number'),
    sortable: true,
    column: 'phone_number'
  },
  email: {
    show: false,
    title: i18n.t('message.email'),
    sortable: true,
    column: 'email'
  },
  status: {
    show: true,
    title: i18n.t('message.status'),
    sortable: true,
    column: 'status'
  },
  user_role_id:{
    show: true,
    title: i18n.t('message.role'),
    sortable: true,
    column: 'user_role_id'
  },
  created_at: {
    show: true,
    title: i18n.t('message.created_at'),
    sortable: true,
    column: 'created_at'
  },
  updated_at: {
    show: true,
    title: i18n.t('message.updated_at'),
    sortable: true,
    column: 'updated_at'
  },  
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: 'settings'
  }
 

};
