export const getters = {
  list: state => state.list,
  inventory: state => state.inventory,
  quiz_info: state => state.quiz_info,
  model: state => state.model,
  rules: state => state.rules,
  columns: state => state.columns,
  filter: state => state.filter,
  pagination: state => state.pagination,
  sort: state => state.sort 
};
