import { structure,getCompanyStructure, getCompanyStaffList,getBranchStaffList,getDepartmentStaffList, updateStructure } from "@/api/staff/structure";
export const actions = {
    structure({ commit }) {
        return new Promise((resolve, reject) => {
            structure()
                .then(res => {
                    commit("SET_LIST", res.data.result.data.structures);                    
                    resolve(res.data.result.data.structures);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    getCompanyStructure({ commit },company_id) {
        return new Promise((resolve, reject) => {
            getCompanyStructure(company_id)
                .then(res => {
                    commit("SET_COMPANY_STRUCTURE", res.data.result.data.company_structure);                    
                    resolve(res.data.result.data.company_structure);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    getCompanyStaffList({ commit },company_id) {
        return new Promise((resolve, reject) => {
            getCompanyStaffList(company_id)
                .then(res => {
                    commit("SET_STAFF_LIST", res.data.result.data.company_staffs);                    
                    resolve(res.data.result.data.company_staffs);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    getBranchStaffList({ commit },branch_id) {
        return new Promise((resolve, reject) => {
            getBranchStaffList(branch_id)
                .then(res => {
                    commit("SET_STAFF_LIST", res.data.result.data.branch_staffs);                    
                    resolve(res.data.result.data.branch_staffs);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    getDepartmentStaffList({ commit },department_id) {
        return new Promise((resolve, reject) => {
            getDepartmentStaffList(department_id)
                .then(res => {
                    commit("SET_STAFF_LIST", res.data.result.data.department_staffs);                    
                    resolve(res.data.result.data.department_staffs);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    updateStructure({ commit }, data) {
        return new Promise((resolve, reject) => {
            updateStructure(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    changeState({commit}, data) {
        commit("SET_STATE", data)
    }
}
