import {i18n} from '@/utils/i18n';
export const columns = {
  id: {
    show: true,
    title: i18n.t('message.n'),
    sortable: true,
    column: 'id'
  },
  performance: {
    show: true,
    title: i18n.t('message.performance_type'),
    sortable: true,
    column: 'performance'
  },
  reason: {
    show: true,
    title: i18n.t('message.reason'),
    sortable: true,
    column: 'reason'
  },
  staff_id: {
    show: true,
    title: i18n.t('message.staff'),
    sortable: true,
    column: 'staff_id'
  },
  date: {
    show: true,
    title: i18n.t('message.date_time'),
    sortable: true,
    column: 'updated_at'
  },
  count: {
    show: true,
    title: i18n.t('message.qty'),
    sortable: true,
    column: 'updated_at'
  },
  header: {
    show: true,
    title: i18n.t('message.details'),
    sortable: true,
    column: 'header'
  },
  status: {
    show: true,
    title: i18n.t('message.status'),
    sortable: true,
    column: 'status'
  },
  branch: {
    show: true,
    title: i18n.t('message.branch'),
    sortable: true,
    column: 'branch'
  },

};
