export default [
  {
    path: "/documents",
    name: "documents",
    meta: {
      layout: "main"
    },
    component: () => import("@/views/documents/index")
  }
];
