import { getCard,getPenalty,getBonus,getSalary,getGraphic,getTransaction, getAdvance,getSalaryToWorkHours } from "@/api/staff/staffTransaction";

export const actions = {    
    
    getCard({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getCard(staff_id)
                .then(res => {
                    commit('SET_STAFF_CARDS', res.data.result.data.cards);                   
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    getPenalty({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getPenalty(staff_id)
                .then(res => {
                    commit('SET_STAFF_PENALTIES', res.data.result.data.penalties);                   
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    getBonus({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getBonus(staff_id)
                .then(res => {
                    commit('SET_STAFF_BONUSES', res.data.result.data.bonuses);                   
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    getAdvance({ commit }, params) {
        return new Promise((resolve, reject) => {
            getAdvance(params)
                .then(res => {
                    commit('SET_STAFF_ADVANCE', res.data.result.data.advance);                   
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    getSalary({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getSalary(staff_id)
                .then(res => {
                    commit('SET_STAFF_SALARY', res.data.result.data.salary);                   
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    getSalaryToWorkHours({ commit }, params) {
      return new Promise((resolve, reject) => {
          getSalaryToWorkHours(params)
              .then(res => {
                  commit('SET_STAFF_WORK_SALARY', res.data.result.data.work_salary);                   
                  resolve(res);
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
  },
    getGraphic({ commit }, staff_id) {
        return new Promise((resolve, reject) => {
            getGraphic(staff_id)
                .then(res => {
                    commit('SET_STAFF_GRAPHIC', res.data.result.data.graphic);                   
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },    

    getTransaction({ commit }, params) {
        return new Promise((resolve, reject) => {
            console.log(params)
            getTransaction(params)
                .then(res => {
                    commit('SET_STAFF_TRANSACTIONS', res.data.result.data.transactions); 
                    // commit('SET_STAFF_PAYMENT_MONTHS', res.data.result.data.payment_months); 
                    // commit('SET_STAFF_START_AND_END_DATES', res.data.result.data.start_and_end_dates); 
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
};
