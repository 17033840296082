<template>
  <div v-loading="completestaffprofile && completestaffprofile.length <= 0" :class="mode ? 'performance__day' : 'performance__night'" class="performance1 mt-3">
    <div class="performers__top">
      <h5 :class="mode ? 'performance__topday' : 'performance__topnight'">{{ $t(title) }}</h5>
    </div>
    <el-row>
      <el-col :span="24">
        <div>
          <ul class="performance__ul"  >
            <template v-if="completestaffprofile">
              <li v-for="perfor in completestaffprofile.slice(0, fixedElem)" :key="'newCome'+perfor.id" class="performance__item" :class="mode ? 'performance__itemday' : 'performance__itemnight'" @click="pushToProfile(perfor)">
                <div class="performance__flex">
                  <div>
                    <div class="performance__image">
                      <img :src="perfor.image ? baseUrl + perfor.image.path : 'img/avatar.jpeg'" alt="">
                    </div>
                    <div class="performance__name">
                      <h5 :class="mode ? 'performance__nameday' : 'performance__namenight'">{{ perfor.name + " " + perfor.last_name }}</h5>
                      <span style="font-size:11px">{{perfor.company?perfor.company.name:''}}</span>
                      <span style="font-size:13px">{{perfor.id}}</span>
                    </div>
                  </div>
                  <div class="performance__per">
                    <span v-if="bottom" :class="mode ? 'performance__nameday' : 'performance__namenight'">{{ perfor.completion_percentage }}%</span>
                    <span v-else style="font-size:16px; color:#04b604">{{ perfor.created_at }}</span>
                  </div>
                </div>
              </li>
            </template>            
          </ul>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'dashboard-personnel',
  props: ['completestaffprofile', 'bottom', 'title', 'fixedElem'],
  computed: {
    ...mapGetters({
      mode: "MODE"
    })
  },
  data(){
   return {
    baseUrl: process.env.VUE_APP_URL.substring(
                0,
                process.env.VUE_APP_URL.length - 3
            ),
   }
  },
}
</script>

<style scoped>
</style>
