import request from '@/utils/request'

export function index(params) {
  return request({
    url: '/settings/graphics',
    method: 'get',
    params: params
  })
}


export function inventory(params) {
  return request({
    url: '/settings/graphic/inventory',
    method: 'get',
    params
  })
}

export function show(id) {
  return request({
    url: `/settings/graphics/${id}`,
    method: 'get'
  })
}
export function getPlanes(id) {
  return request({
    url: `/settings/graphic/${id}/get-planes`,
    method: 'get',
  })
}
export function addPlanes(data) {
  return request({
    url: '/settings/graphic/add-planes',
    method: 'post',
    data
  })
}
export function updatePlanes(data) {
  return request({
    url: `/settings/graphic/update-planes/${data.id}`,
    method: 'post',
    data
  })
}
export function deletePlanes(id) {
  return request({
    url: `/settings/graphic/delete-planes/${id}`,
    method: 'post',
  })
}
export function store(data) {
  return request({
    url: '/settings/graphics',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: `/settings/graphics/${data.id}`,
    method: 'put',
    data
  })
}

export function destroy(id) {
  return request({
    url: `/settings/graphics/${id}`,
    method: 'delete',
  })
}
