<template>
  <div v-loading="staffbymood.length >= 0" :class="mode ? 'home__day' : 'home__night'" class="
      style-scuer
      d-flex
      mt-3
      f-between
      p-3">
    <div :class="mode ? 'performance__topday' : 'performance__topnight'" class="title-new-home-page mb-2">
      {{$t("Настроение сотрудников")}}
    </div>
    <div class="mood__all">
      <div class="flex__mood mt-2">
        <div class="mood__box mr-2" :class="mode ? 'mood__boxday' : 'mood__boxnight'" @dblclick="showStaffList('good', 'getMoodStaffs', branch_id)">
            <div>
              <span class="mood__excellent">
                <i class="fa-regular fa-face-grin-beam"></i>
              </span>
            </div>
            <div class="mood__sticker">
              <h4 :class="mode ? '' : 'text__night2'">{{ staffbymood.good }}</h4>
              <span>Отличные</span>
            </div>
        </div>
        <div class="mood__box ml-2" :class="mode ? 'mood__boxday' : 'mood__boxnight'" @dblclick="showStaffList('normal', 'getMoodStaffs', branch_id)">
          <div>
            <span class="mood__good">
              <i class="fa-regular fa-face-grin"></i>
            </span>
          </div>
          <div class="mood__sticker">
            <h4 :class="mode ? '' : 'text__night2'">{{ staffbymood.normal }}</h4>
            <span>Хорошее</span>
          </div>
        </div>
      </div>
      <div class="mood__bottom">
        <div class="mood__box" :class="mode ? 'mood__boxday' : 'mood__boxnight'" @dblclick="showStaffList('bad', 'getMoodStaffs')">
          <div>
            <span class="mood__bad">
              <i class="fa-regular fa-face-frown"></i>
            </span>
          </div>
          <div class="mood__sticker">
            <h4 :class="mode ? '' : 'text__night2'">{{ staffbymood.bad }}</h4>
            <span>Плохие</span>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
    title="I'm outer Drawer"
    :visible.sync="showUseerModal"
    size="30%"
    :with-header="false"
    ref="show-users"
    @opened="drawerOpened('show-users')"
  >
    <div>
      <show-users-home
        :staff="selected"
        @closeUserModal="closeUserModal"
        :child="child"
        ref="show-users"
      ></show-users-home>
    </div>
  </el-drawer>
  </div>
</template>

<script>
import list from "@/utils/mixins/list";
import showUsersHome from "./show-users-home.vue";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: 'dashboard-mood',
   
  props:{
    staffbymood: {
     type:Object,
    },
    branch_id: {
      type: Boolean,
    },
  },
  props: ['staffbymood', 'branch_id'],
  data: () => ({
    showUseerModal: false,
    selected: null,
    child: {},
  }),
  components:{
    showUsersHome
  },
  computed: {
    ...mapGetters({
      mode: "MODE"
    })
  },
  methods: {
    showStaffList(mood, method='getMoodStaffs') {
      this.showUseerModal = true;
      this.child.branch_id = this.branch_id;
      this.child.method = method;
      this.child.mood = mood;
    },
    closeUserModal(val) {
      this.showUseerModal = val;
    },
    drawerOpened(ref) {
      if (this.$refs[ref]) {
        if (_.isFunction(this.$refs[ref].opened)) {
          this.$refs[ref].opened(this.child);
        }
      }
    },
   },
   
}
</script>

<style>

</style>
