<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.organization')"
      filterable
      clearable
      :size="size"
      class="d-block"
      :class="mode ? 'input__day' : 'input__night'"
    >
      <el-option
        v-for="(organization, index) in organizations"
        :key="'organizations-' + index"
        :label="organization.name"
        :value="organization.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
  },
  watch: {
    id: {
      handler: function () {
        setTimeout(() => {
          this.selected = this.id;
        });
      },
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.organizations && this.organizations.length === 0) this.updateInventory();
  },
  computed: {
    ...mapGetters({
      organizations: "organization/inventory",
      mode:'MODE'
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "organization/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>
