import { columns } from './properties/columns'
import { filter } from './properties/filter'
import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "./properties/sort";
import { rules } from "./properties/rules";
import { model } from "./properties/model";

export const state = {
    list: [],
    inventory: [],
    staffAttendance:[],
    model: JSON.parse(JSON.stringify(model)),
    columns: columns,
    filter: filter,
    pagination: JSON.parse(JSON.stringify(pagination)),
    paginationAttendance: JSON.parse(JSON.stringify(pagination)),
    sort: JSON.parse(JSON.stringify(sort)),   
    rules: rules   
};
