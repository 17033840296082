<template>
  <div>
    <ul class="daily__box">
      <li v-for="c in persons" :key="c.id" class="daily__item">
        <div class="daily__info" :class="mode ? 'daily__infoday' : 'daily__infonight'">
          <div class="daily__squ">
            <div>
              <div class="daily__flex">
                <span class="daily__per">{{ c.coming }}%</span>
                <span class="daily__sec">Приход</span>
              </div>
              <div class="daily__flex">
                <span class="daily__per">{{ c.pending }}%</span>
                <span class="daily__sec">В ожидании</span>
              </div>
              <div class="daily__flex">
                <span class="daily__per">{{ c.stay }}%</span>
                <span class="daily__sec">Доп.ост</span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    persons: [
      {
        coming: 72,
        pending: 26,
        stay: 2,
        date: '13/06/2022',
        header: 'Понедельник'
      },
      {
        coming: 86,
        pending: 62,
        stay: 2,
        date: '14/06/2022',
        header: 'Вторник'
      },
      {
        coming: 78,
        pending: 20,
        stay: 2,
        date: '15/06/2022',
        header: 'Среда'
      },
      {
        coming: 79,
        pending: 19,
        stay: 2,
        date: '16/06/2022',
        header: 'Четверг'
      },
      {
        coming: 90,
        pending: 8,
        stay: 2,
        date: '17/06/2022',
        header: 'Пятница'
      },
      {
        coming: 29,
        pending: 69,
        stay: 2,
        date: '18/06/2022',
        header: 'Суббота'
      },
      {
        coming: 5,
        pending: 93,
        stay: 2,
        date: '19/06/2022',
        header: 'Воскресенье'
      },
    ]
  }),
  computed: {
    ...mapGetters({
      mode: "MODE"
    })
  }
}
</script>

<style>

</style>
