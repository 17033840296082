<template>
  <div class="gc-country-input-wrap d-flex p-relative">
    <div class="gc-country-flag d-flex align-center px-2 pointer" ref="flagButton">
      <img class="mr-2" src="/img/flag-uzbekistan.png" alt="Flag" />
      <i class="el-icon-arrow-down"></i>
    </div>

    <div class="gc-country-dropdown p-absolute rounded" ref="flagDropdown">
      <perfect-scrollbar class="gc-dropdown-scroll">
        <div
          v-for="item in countryList"
          :key="item.id"
          @click="getCountryItem(item)"
          class="gc-country-dropdown__list d-flex align-center px-2 transition pointer"
        >
          <img :src="item.flag" alt="Flag" />
          <span class="gc-country-prefix mx-2">{{item.code}}</span>
          <span class="gc-country-name">{{item.name}}</span>
        </div>
      </perfect-scrollbar>
    </div>
    <input
      @input="findCountryItem"
      type="text"
      class="gc-country-input"
      placeholder="+998"
      ref="countryInput"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      countryList: [
        {
          id: 1,
          flag: "/img/flag-uzbekistan.png",
          code: "+998",
          name: "Узбекистан"
        },
        {
          id: 2,
          flag: "/img/flag-usa.png",
          code: "+1",
          name: "USA"
        },
        {
          id: 3,
          flag: "/img/flag-russia.png",
          code: "+7",
          name: "Россия"
        },
        {
          id: 4,
          flag: "/img/flag-kazakhstan.png",
          code: "+XXX",
          name: "Казахстан"
        }
      ]
    };
  },
  mounted() {
    const { flagButton, flagDropdown } = this.$refs;

    flagButton.addEventListener("click", function() {
      flagDropdown.classList.toggle("active");
    });
  },
  methods: {
    getCountryItem(country) {
      const { countryInput, flagDropdown, flagButton } = this.$refs;
      const { code, flag, name } = country;

      flagDropdown.classList.remove("active");
      countryInput.focus();

      countryInput.placeholder = code;
      flagButton.children[0].src = flag;
    },

    findCountryItem() {
      const { countryInput, flagButton } = this.$refs;

      this.countryList.find(item => {
        if (item.code.startsWith(event.target.value)) {
          flagButton.children[0].src = item.flag;
          countryInput.placeholder = item.code;
        }
      });
    }
  }
};
</script>

<style>
</style>