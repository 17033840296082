import { come_in, not_come_in, not_come_sender, come_sender} from "@/api/staff/homeComeStaff";

export const actions = {   
  come_in({ commit }, params={}) {
      return new Promise((resolve, reject) => {
        come_in(params)
              .then(res => {
                let arr =[];
                let respon = res.data.result.data.staffs;
                respon.forEach((value, index) => {
                    value.name =  value.name
                    value.last_name =  value.last_name
                    value.middle_name =  value.middle_name
                    value.position_id = value.position?value.position.name:'';                       
                    arr.push(value);
                });
                  commit("SET_EXCEL", arr);
                  commit("SET_STAFF_LIST", res.data.result.data.staffs);  
                  commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                  resolve(res.data.result.data.staffs);
              })
              .catch(err => {
                  reject(err.response.data);
          });
      });
    },
    not_come_in({ commit }, params={}) {
      return new Promise((resolve, reject) => {
        not_come_in(params)
              .then(res => {
                  let arr =[];
                  let respon = res.data.result.data.staffs;
                  respon.forEach((value, index) => {
                      let item = [];
                      item.id =  value.id;
                      item.name =  value.name;
                      item.last_name =  value.last_name;
                      item.middle_name =  value.middle_name;
                      item.position = value.position?value.position.name:'';                       
                      item.company = value.company?value.company.name:'';                       
                      arr.push(item);
                  });
                  commit("SET_EXCEL", arr);
                  commit("SET_STAFF_LIST", res.data.result.data.staffs);                    
                  commit("SET_STAFF_COUNT", res.data.result.data.count);                    
                  resolve(res.data.result.data.staffs);
              })
              .catch(err => {
                  reject(err.response.data);
              });
      });
    },
    updateSort({ commit }, sort) {
      commit("SET_SORT", sort)
  },

  updateFilter({ commit }, filter) {
      commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
  },

  updateColumn({ commit }, obj) {
      commit('UPDATE_COLUMN', obj)
  },
  updatePagination({ commit }, pagination) {
      commit('UPDATE_PAGINATION', pagination)
  },
  refreshData({ commit }) {
      return new Promise((resolve, reject) => {
          commit("REFRESH");
          resolve()
      })
  },
  empty({ commit }) {
    return new Promise((resolve, reject) => {
        commit("EMPTY_MODEL");
        resolve()
    })
},
};
