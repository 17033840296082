import request from '@/utils/request';

export function index(params) {
  return request({
    url: '/kpp/employee_kpps',
    method: 'get',
    params: params
  })
}

// export function inventory(params) {
//   return request({
//     url: '/kpp/employee_kpp/inventory',
//     method: 'get',
//     params
//   })
// }

export function show(id) {
  return request({
    url: `/kpp/employee_kpps/${id}`,
    method: 'get'
  })
}

export function store(data) {
  return request({
    url: '/kpp/employee_kpps',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: `/kpp/employee_kpps/${data.id}`,
    method: 'put',
    data
  })
}

export function destroy(id) {
  return request({
    url: `/kpp/employee_kpps/${id}`,
    method: 'delete',
  })
}
export function updateArrivalDateTimes(data) {
  return request({
    url: `/kpp/employee_kpp/update_arrival_date_times/${data.id}`,
    method: 'put',
    data
  })
}
