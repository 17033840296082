import request from '@/utils/request'

export function index(params) {
    return request({
        url: '/staffs/transports',
        method: 'get',
        params: params
    })
}

export function inventory(params) {
    return request({
        url: '/staffs/transport/inventory',
        method: 'get',
        params
    })
}

export function show(id) {
    return request({
        url: `/staffs/transports/${id}`,
        method: 'get'
    })
}

export function store(data) {
    return request({
        url: '/staffs/transports',
        method: 'post',
        data
    })
}

export function update(data) {
    return request({
        url: `/staffs/transports/${data.id}`,
        method: 'put',
        data
    })
}

export function destroy(id) {
    return request({
        url: `/staffs/transports/${id}`,
        method: 'delete',
    })
}
