<template>
  <div>
    <el-select :class="mode ? 'input__day' : 'input__night'" :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.category')" filterable clearable :multiple="multiple"
      :size="size" class="d-block">
      <el-option v-for="(category,index) in inventory_categories" :key="'inventory_categories-' + index" :label="category.name" :value="category.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  import { i18n  } from '@/utils/i18n';
  export default {
    props: {
        size: {
            default: 'small'
        },
        placeholder: {
            default: null,
        },
        id:{
          default: null
        },
        multiple:{
            type: Boolean,
            default: false,
        }
    },
     watch:{
      id:{
        handler: function() {
            this.selected = this.id
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
      }
    },
    mounted() {
      if (this.inventory_categories && this.inventory_categories.length === 0) this.updateInventory()
    },
    computed: {
      ...mapGetters({
        inventory_categories: 'inventoryCategory/inventory',
        mode:'MODE'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'inventoryCategory/inventory',
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
