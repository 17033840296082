export default [
  {
    path: "/document-types",
    name: "documentType",
    meta: {
      layout: "main"
    },
    component: () => import("@/views/documentType/index")
  }
];
