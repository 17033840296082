import { index, inventory, show, store, update, destroy,fireStaffs, telegramStaff } from "@/api/staff/staff";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {                    
                   
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                      let arr =[];
                      let respon = res.data.result.data.staffs;
                      respon.forEach((value, index) => {
                          // value.department = value.department?value.department.name:null;
                          value.gender_id = value.gender?value.gender.name:'';
                          value.nation_id = value.nation?value.nation.nationality:'';
                          value.position_id = value.position?value.position.name:'';
                          value.department_id = value.department?value.department.name:'';
                          value.work_type_id = value.work_type?value.work_type.work_type_name:'';
                          value.graphic_id = value.graphic?value.graphic.name:'';
                          value.status_id = value.status?value.status.name:'';
                          value.district_id = value.district?value.district.name:'';
                          value.category = value.category?value.category.name:'';
                          value.work_type_id = value.work_type?value.work_type.work_type_name:'';
                          value.telegram_two = value.telegram;
                          value.telegram_staff_two = value.telegram_staff;
                          value.children_two = value.children;
                          value.telegram = value.telegram?"Есть":"Нет";
                          value.telegram_staff = value.telegram_staff?"Есть":"Нет";
                          value.children = value.children?"Есть":"Нет";
                          value.phone_number = value.phone_number?("+"+value.phone_number.slice(0, 3)+"_"+value.phone_number.slice(3)):'';

                          arr.push(value);
                      });
                    commit("SET_EXCEL", arr);

                    commit("SET_LIST", res.data.result.data.staffs);
                    resolve(res.data.result.data.staffs);
                   
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    test({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_TEST", res.data.result.data.staffs);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res.data.result.data.staffs);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },
    dismissed({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_DISMISSED", res.data.result.data.staffs);
                    commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res.data.result.data.staffs);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {
                commit("SET_INVENTORY", res.data.result.data.staffs );
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
  //   active_inventory({ commit }, params = {}) {
  //     return new Promise((resolve, reject) => {
  //         active_inventory(params).then(res => {
  //             commit("SET_INVENTORY", res.data.result.data.staffs );
  //             resolve(res.data)
  //         }).catch(err => {
  //             reject(err.response.data)
  //         })
  //     })
  // },
    telegramStaff({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
        telegramStaff(params).then(res => {
              commit("SET_TELEGRAM_STAFFS", res.data.result.data.staffs );
              resolve(res.data)
          }).catch(err => {
              reject(err.response.data)
          })
      })
  },

    filter({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {
                commit("SET_FILTER", res.data.result.data.staffs );
                resolve(res.data)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.staff);                   
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    fireStaffs({ commit }, data) {
        return new Promise((resolve, reject) => {
            fireStaffs(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    update({ commit }, data) {
        return new Promise((resolve, reject) => {
            update(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
