export const model = {
  id: null,
  name: '',
  last_name: '', 
  phone_number: '', 
  role_id: null,
  email: '',
  password: '',
  confirm_password: '',
  status: null,       
  created_at: '',
  updated_at: '',
};
