export const model = {
    id: null,
    name: '',
    last_name: '',
    middle_name: '',
    date_of_birth: null,
    gender_id: null,
    nation_id: null,
    status_id: null,
    phone_number: null,
    email: '',
    allow_to_be_user: false,
    company_id: null,
    branch_id: null,
    department_id: null,
    created_at: '',
    updated_at: '',
    allow_salary: false,
    telegram: null,
    // card_number: null,
    // expiry_date: null,
    // card_authority: null,
    // type: null,
    // currency_id: null,
    // official_amount: null,
    // unofficial_amount: null,
    // amount_per_hour: null,
    // salary_status_id: null,
    
};
