import request from '@/utils/request'

export function come_in(params) {
  return request({
      url: `/dashboard/staffs/come_in`,
      method: 'get',
      params
  })
}

export function not_come_in(params) {
  return request({
      url: `/dashboard/staffs/not_come_in`,
      method: 'get',
      params
  })
}
