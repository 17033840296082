import { state } from "./state";

export const getters = {
  statistics: state => state.statistics,
  newcomestaffs: state => state.newcomestaffs,
  completestaffprofile: state => state.completestaffprofile,
  staffagestatistic: state => state.staffagestatistic,
  staffupdate: state => state.staffupdate,
  genderstatistic: state => state.genderstatistic,
  todaybirthdays: state => state.todaybirthdays,
  expectedbirthdays: state => state.expectedbirthdays,
  pastbirthdays: state => state.pastbirthdays,
  staffbyeducation: state => state.staffbyeducation,
  staffbyreason: state => state.staffbyreason,
  staffworktype: state => state.staffworktype,
  workexperience: state => state.workexperience,
  completeprofilethefiliale: state => state.completeprofilethefiliale,
  employessbyregion: state => state.employessbyregion,
  stafffromcandidate: state => state.stafffromcandidate,
  candidate: state => state.candidate,
  releaseandreception: state => state.releaseandreception,
  source: state => state.source,
  wages: state => state.wages,
  averagesalarybybranches: state => state.averagesalarybybranches,
  staffattendences: state => state.staffattendences,
  faceidregistration: state => state.faceidregistration,
  attendstaffweek: state => state.attendstaffweek,
  visitors: state => state.visitors,
  stafftelegraminformation: state => state.stafftelegraminformation,
  topmanagers: state => state.topmanagers,
  employeekpp: state => state.employeekpp,
  transportkpp: state => state.transportkpp,
  reasonbyoperation: state => state.reasonbyoperation,
  tmsstatistic: state => state.tmsstatistic,
  tmslist: state => state.tmslist,
  toptoiletattendants: state => state.toptoiletattendants,
  hasSalayStaffs: state => state.hasSalayStaffs,
  requestsForToday: state => state.requestsForToday,
  staffbymood: state => state.staffbymood,
  situationfortoday: state => state.situationfortoday,
  topstaffwithtotalwork: state => state.topstaffwithtotalwork,

};
