export default [
  {
    path: '/vacancy',
    name: 'vacancy',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/vacancy/index')
  },
  {
    path: '/vacancy/:id',
    name: 'vacancy_show',
    meta: {
      layout: 'main',
    },  
    component: () => import('@/views/vacancy/show')
  }

]
