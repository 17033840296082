import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    staff : {
      show: true,
      title: i18n.t('message.staff'),
      sortable: true,
      column: 'staff'
    },
    manager : {
      show: true,
      title: i18n.t('message.manager'),
      sortable: true,
      column: 'manager'
    },
    organization : {
      show: true,
      title: i18n.t('message.organizations'),
      sortable: true,
      column: 'organization'
    },
    amount : {
      show: true,
      title: i18n.t('message.amount'),
      sortable: true,
      column: 'amount'
    },
    comment : {
      show: true,
      title: i18n.t('message.comment'),
      sortable: true,
      column: 'comment'
    },
    start_date_time : {
        show: true,
        title: i18n.t('message.start_date_time'),
        sortable: true,
        column: 'start_date_time'
    },
    end_date_time : {
      show: true,
      title: i18n.t('message.end_date_time'),
      sortable: true,
      column: 'end_date_time'
    },
    status : {
      show: true,
      title: i18n.t('message.status'),
      sortable: true,
      column: 'status'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
