export const rules = {
  name: [{
    required: true,
    message: 'Пожалуйста, введите название ',
    trigger: 'change'
  }, ],
  company_ids: [{
    required: true,
    message: 'Пожалуйста, введите название ',
    trigger: 'change'
  }, ],
};
