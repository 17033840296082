import request from '@/utils/request'
export function staffAttendance(params) {
    return request({
        url: '/staffs/staff_attendances',
        method: 'get',
        params: params
    })
}

export function index(params) {
    return request({
        url: '/staffs/attendances',
        method: 'get',
        params: params
    })
}
export function store(data) {
  return request({
      url: '/staffs/attendances/store',
      method: 'post',
      data
  })
}
