import request from '@/utils/request'

export function index(params) {
  return request({
      url: 'staffs/bonus/get_staff_bonus',
      method: 'get',
      params: params
  })
}

export function inventory(params) {
  return request({
      url: 'staffs/bonus/get_staff_bonus_inventory',
      method: 'get',
      params
  })
}

export function store(data) {
  return request({
      url: `staffs/bonus/add_staff_bonus/${data.staff_id}`,
      method: 'post',
      data
  })
}

export function update(data) {   
  return request({
      url: `staffs/bonus/update_staff_bonus/${data.staff_id}/${data.id}`,
      method: 'put',
      data
  })
}

export function destroy(data) {
  return request({
      url: `staffs/bonus/delete_staff_bonus/${data.staff_id}/${data.id}`,
      method: 'delete',
  })
}
