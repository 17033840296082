import request from '@/utils/request'

export function index(params) {
  return request({
      url: '/staffs/additionals',
      method: 'get',
      params: params
  })
}
export function staff_list(params) {
  return request({
      url: `/additional_work/staffs/all`,
      method: 'get',
      params
  })
}
export function getDepartmentStaffListComeIn(params) {
  return request({
      url: `/attendances_department/departments/staffs/come`,
      method: 'get',
      params: params
  })
}
export function getDepartmentStaffListIzn(params) {
  return request({
      url: `/attendances_department/departments/staffs/izn`,
      method: 'get',
      params: params         
  })
}
export function getDepartmentStaffListNotComeIn(params) {
  return request({
      url: `/attendances_department/departments/staffs/notizn`,
      method: 'get',
      params: params   
  })
}
