import request from "@/utils/request";

export function onlineWork(params) {
  return request({
    url: "/staffs/online-works",
    method: "get",
    params: params
  });
}
export function reasons(params) {
  return request({
    url: `/assent/vacation/reasons`,
    method: "get",
    params
  });
}
export function store(data) {
  return request({
    url: "/staffs/online-works",
    method: "post",
    data
  });
}
export function show(id) {
  return request({
    url: `/staffs/online-works/${id}`,
    method: "get"
  });
}

export function update(data) {
  return request({
    url: `/staffs/online-works/update/${data.id}`,
    method: "post",
    data
  });
}
export function destroy(id) {
  return request({
    url: `/staffs/online-works/${id}`,
    method: "delete"
  });
}
