export const filter = {
  id : '',
  name:'',
  last_name: '',
  middle_name: '',
  phone_number: '',
  email:'',  
  gender_id: null,
  user_role_id: null,
  date_of_birth: '',
  status: null,
  date_of_birth: '',
  created_at: '',
  updated_at: '',
};
