import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.nameGiven'),
        sortable: true,
        column: 'name'
    },
    gender_ids : {
        show: true,
        title: i18n.t('message.gender'),
        sortable: true,
        column: 'gender_ids'
    },
    company_id : {
        show: true,
        title: i18n.t('message.company'),
        sortable: true,
        column: 'company_id'
    },
    min_wage : {
      show: true,
      title: i18n.t('message.min_wage_y'),
      sortable: true,
      column: 'min_wage'
    },
    max_wage : {
      show: true,
      title: i18n.t('message.max_wage_y'),
      sortable: true,
      column: 'max_wage'
    },
    branch_id : {
        show: true,
        title: i18n.t('message.branch'),
        sortable: true,
        column: 'branch_id'
    },    
    department_ids : {
        show: true,
        title: i18n.t('message.department'),
        sortable: true,
        column: 'department_ids'
    },
    requirement_ids : {
        show: false,
        title: i18n.t('message.requirements'),
        sortable: true,
        column: 'requirement_ids'
    },
    min_age : {
        show: true,
        title: i18n.t('message.age'),
        sortable: true,
        column: 'min_age'
    },

    max_age : {
        show: true,
        title: i18n.t('message.max_age'),
        sortable: true,
        column: 'max_age'
    },

    priority_id : {
        show: true,
        title: i18n.t('message.priority'),
        sortable: true,
        column: 'priority_id'
    },
    url : {
        show: true,
        title: i18n.t('message.url'),
        sortable: true,
        column: 'url'
    },
    candidate_count : {
        show: true,
        title: i18n.t('message.limit'),
        sortable: true,
        column: 'candidate_count'
    },
    position_id : {
        show: true,
        title: i18n.t('message.position'),
        sortable: true,
        column: 'position_id'
    },
    graphic_ids : {
        show: true,
        title: i18n.t('message.graphic'),
        sortable: true,
        column: 'graphic_ids'
    },

    limit : {
        show: true,
        title: i18n.t('message.limit'),
        sortable: true,
        column: 'limit'
    },
    
    employment_type_id : {
        show: true,
        title: i18n.t('message.employmentType'),
        sortable: true,
        column: 'employment_type_id'
    },
    language_ids : {
        show: true,
        title: i18n.t('message.language'),
        sortable: true,
        column: 'language_ids'
    },
    skill_ids : {
        show: true,
        title: i18n.t('message.skill'),
        sortable: true,
        column: 'skill_ids'
    },

    currency_ids : {
        show: true,
        title: i18n.t('message.currency'),
        sortable: true,
        column: 'currency_ids'
    },

    min_wage : {
        show: true,
        title: i18n.t('message.min_wage'),
        sortable: true,
        column: 'min_wage'
    },

    max_wage : {
        show: true,
        title: i18n.t('message.max_wage'),
        sortable: true,
        column: 'max_wage'
    },

    region_id : {
        show: true,
        title: i18n.t('message.province'),
        sortable: true,
        column: 'region_id'
    },

    district_id : {
        show: true,
        title: i18n.t('message.district_id'),
        sortable: true,
        column: 'district_id'
    },

    description : {
        show: true,
        title: i18n.t('message.description'),
        sortable: true,
        column: 'description'
    },
    status_id : {
        show: true,
        title: i18n.t('message.status'),
        sortable: true,
        column: 'status_id'
    },
   
    
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
