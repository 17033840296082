export const rules = {
    staff_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ], 
    currency_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],  
    account_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],  
    payment_type: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    amount: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    salary_type: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    date_of_end: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    payment_month: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ]
};
