import {
  explanatory_letters,
  store,
  show,
  update,
  destroy
} from "@/api/staff/explanatory_letters";

export const actions = {
  explanatoryLetter({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      explanatory_letters(params)
        .then(res => {
          commit("SET_LIST", res.data.result.data.explanatory_letters);
          commit("UPDATE_PAGINATION", {
            key: "total",
            value: res.data.result.data.pagination.total
          });
          resolve(res);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  },

  store({ commit }, data) {
    return new Promise((resolve, reject) => {
      store(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  show({ commit }, id) {
    return new Promise((resolve, reject) => {
      show(id)
        .then(res => {
          commit("SET_MODEL", res.data.result.data.explanatory_letter);
          resolve(res);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      update(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  destroy({ commit }, id) {
    return new Promise((resolve, reject) => {
        destroy(id)
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err);
            });
    });
},
  empty({ commit }) {
    return new Promise((resolve, reject) => {
      commit("EMPTY_MODEL");
      resolve();
    });
  },
  updateSort({ commit }, sort) {
    commit("SET_SORT", sort);
  },

  updateFilter({ commit }, filter) {
    commit("SET_FILTER", JSON.parse(JSON.stringify(filter)));
  },

  updateColumn({ commit }, obj) {
    commit("UPDATE_COLUMN", obj);
  },
  updatePagination({ commit }, pagination) {
    commit("UPDATE_PAGINATION", pagination);
  },
  updatePaginationAttendance({ commit }, pagination) {
    commit("UPDATE_PAGINATION_ATTENDANCE", pagination);
  },
  refreshData({ commit }) {
    return new Promise((resolve, reject) => {
      commit("REFRESH");
      resolve();
    });
  }
};
