import request from '@/utils/request'

export function index(params) {
  return request({
      url: '/settings/penalty-reasons',
      method: 'get',
      params: params
  })
}


export function inventory(params) {
  return request({
      url: '/settings/penalty-reason/inventory',
      method: 'get',
      params
  })
}
export function sanction(params) {
  return request({
      url: '/settings/penalty-reason/sanction',
      method: 'get',
      params
  })
}
export function bonuses(params) {
  return request({
      url: '/settings/penalty-reason/bonuses',
      method: 'get',
      params
  })
}
export function gatStaffSalary(id) {
  return request({
      url: `/settings/get-active-salary/${id}`,
      method: 'get',
  })
}

export function show(id) {
  return request({
      url: `/settings/penalty-reasons/${id}`,
      method: 'get'
  })
}

export function store(data) {
  return request({
      url: '/settings/penalty-reasons',
      method: 'post',
      data
  })
}

export function update(data) {
  return request({
      url: `/settings/penalty-reasons/${data.id}`,
      method: 'put',
      data
  })
}

export function destroy(id) {
  return request({
      url: `/settings/penalty-reasons/${id}`,
      method: 'delete',
  })
}
