<template>
  <div>
    <el-select
      :class="mode ? 'input__day' : 'input__night'"
      :value="selected"
      @input="dispatch"
      @change="cChange()"
      :placeholder="placeholder || $t('message.documentType')"
      filterable
      clearable
      :multiple="multiple"
      :size="size"
      class="d-block"
    >
      <el-option
        v-for="(document_type, index) in document_types"
        :key="'document_types-' + index"
        :label="document_type.name"
        :value="document_type.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: {
    size: {
      default: "small"
    },
    placeholder: {
      default: null
    },
    id: {
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    id: {
      handler: function() {
        this.selected = this.id;
      },
      immediate: true
    }
  },
  data() {
    return {
      selected: null
    };
  },
  mounted() {
    if (this.document_types && this.document_types.length === 0)
      this.updateInventory();
  },
  computed: {
    ...mapGetters({
      document_types: "documentType/inventory",
      mode: "MODE"
    })
  },
  methods: {
    ...mapActions({
      updateInventory: "documentType/inventory"
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
    cChange() {
      this.$emit("c-change");
    }
  }
};
</script>
