<template>
    <div>
        <el-button v-if="can_create" v-can="v_can_create" size="small" class="btu-color" @click="create()">
          {{$t("message.create")}}
        </el-button>
        <el-dropdown class="setting-cheek ml-2">
            <el-button
                class="padding_none"
                size="small"
                icon="el-icon-open"
            ></el-button>
            <el-dropdown-menu slot="dropdown" :class="mode ? 'light_mode' : 'night_mode'">
                <el-dropdown-item
                    v-for="(column, index) in columns"
                    :key="'drop' + index"
                >
                    <el-checkbox
                        :checked="column.show"
                        @change="column.show = !column.show"
                        
                        >{{ column.title }}
                    </el-checkbox>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
export default {
    props: {
        columns: {
            type: Object,
        },
        can_create:{
            type: Boolean,
            default: true
        },
        v_can_create:{
            type: String,
            default: ''
        }
    },
    computed: {
      ...mapGetters({
        mode:'MODE'
      })
    },
    methods: {
        check: function (column, event) {
            this.$emit("c-change", {
                key: column,
                value: event,
            });
        },
        create() {
            this.$emit("c-create", true);
        },
    },
};
</script>
<style scoped lang="scss">
.el-dropdown-menu__item > label {
    margin-bottom: 0.1rem !important;
}

.el-dropdown-menu {
    max-height: 720px !important;
    overflow-y: scroll;
}
.el-dropdown-menu__item,
.el-checkbox__label,
.el-checkbox {
    font-size: 12px !important;
}
.night_mode{
  border: 1px dashed #323248 !important;
  background: #0f0e1e;
  color: #fff;
  .el-dropdown-menu__item{
    .el-checkbox{
      color: #fff !important;
      
    }
  }
  .el-dropdown-menu__item:hover{
    .el-checkbox{
      color: rgb(67, 2, 2) !important;

      
    }
  }
  .el-dropdown-menu__item:focus, 
  .el-dropdown-menu__item:not(.is-disabled):hover{
    .el-checkbox{
      color: #409EFF !important;      
    }
  }

}
</style>
