import request from '@/utils/request';

export function index(params) {
  return request({
    url: '/kpp/tms',
    method: 'get',
    params: params
  })
}

export function inventory(params) {
  return request({
    url: '/kpp/tm/inventory',
    method: 'get',
    params
  })
}

export function show(id) {
  return request({
    url: `/kpp/tms/${id}`,
    method: 'get'
  })
}

export function store(data) {
  return request({
    url: '/kpp/tms',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: `/kpp/tms/${data.id}`,
    method: 'put',
    data
  })
}

export function destroy(id) {
  return request({
    url: `/kpp/tms/${id}`,
    method: 'delete',
  })
}
export function updateArrivalDateTimes(data) {
  return request({
    url: `/kpp/tm/update_arrival_date_times/${data.id}`,
    method: 'put',
    data
  })
}
export function updateLeftDateTimes(data) {
  return request({
    url: `/kpp/tm/update_left_date_times/${data.id}`,
    method: 'put',
    data
  })
}
