export const rules = {
    name: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    code: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    symbol: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
};
