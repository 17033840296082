import request from '@/utils/request'

export function index(params) {
    return request({
        url: '/staffs/staffs',
        method: 'get',
        params: params
    })
}


export function inventory(params) {
    return request({
        url: '/staffs/staff/inventory',
        method: 'get',
        params
    })
}
// export function active_inventory(params) {
//   return request({
//       url: '/staffs/staff/active_inventory',
//       method: 'get',
//       params
//   })
// }
export function telegramStaff(params) {
  return request({
      url: '/staffs/staff/telegram-user',
      method: 'get',
      params
  })
}

export function show(id) {
    return request({
        url: `/staffs/staffs/${id}`,
        method: 'get'
    })
}

export function getCard(staff_id) {
    return request({
        url: `/staffs/staffs/get_card/${staff_id}`,
        method: 'get'
    })
}

export function getPenalty(staff_id) {
    return request({
        url: `/staffs/staffs/get_penalty/${staff_id}`,
        method: 'get'
    })
}

export function store(data) {
    return request({
        url: '/staffs/staffs',
        method: 'post',
        data
    })
}
export function fireStaffs(data) {
    return request({
        url: '/staffs/fire_staffs',
        method: 'post',
        data
    })
}

export function update(data) {
    return request({
        url: `/staffs/staffs/${data.id}`,
        method: 'post',
        data
    })
}

export function destroy(id) {
    return request({
        url: `/staffs/staffs/${id}`,
        method: 'delete',
    })
}
