import request from "@/utils/request";

export function explanatory_letters(params) {
  return request({
    url: "/staffs/explanatory_letters",
    method: "get",
    params: params
  });
}
export function store(data) {
  return request({
    url: "/staffs/explanatory_letters",
    method: "post",
    data
  });
}
export function show(id) {
  return request({
    url: `/staffs/explanatory_letters/${id}`,
    method: "get"
  });
}

export function update(data) {
  return request({
    url: `/staffs/explanatory_letters/update/${data.id}`,
    method: "post",
    data
  });
}
export function destroy(id) {
  return request({
    url: `/staffs/explanatory_letters/${id}`,
    method: "delete"
  });
}
