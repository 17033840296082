import request from '@/utils/request'

export function index(params) {
  return request({
      url: '/recruitments/candidates',
      method: 'get',
      params: params
  })
}


export function inventory(params) {
  return request({
      url: '/recruitments/candidate/inventory',
      method: 'get',
      params
  })
}

export function show(id) {
  return request({
      url: `/recruitments/candidates/${id}`,
      method: 'get'
  })
}

export function store(data) {    
  return request({
      url: '/recruitments/candidates',
      method: 'post',
      data
  })
}

export function update(data) {    
  return request({
      url: `/recruitments/candidates/${data.id}`,
      method: 'post',
      data
  })
}

export function destroy(id) {
  return request({
      url: `/recruitments/candidates/${id}`,
      method: 'delete',
  })
}

export function createCandidate(data, vacancyUuid) {
  return request({
      url: `/vacancy/${vacancyUuid}`,
      method: 'post',
      data
  })
}

export function downloadFile(data) {
  return request({
      url: `recruitments/candidate/download-resume/${data.id}`,
      method: 'get',
      responseType:'blob'
  })
}
export function storeAplicationCandidates(data, vacancyUuid) {    
  return request({
      url: `/vacancy/${vacancyUuid}`,
      method: 'post',
      data
  })
}
export function getQuizInfo(id) {
  return request({
      url: `recruitments/candidate/quiz-info/${id}`,
      method: 'get'
  })
}
