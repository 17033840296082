export const model = {
    id: null,
    name: '',
    code: '',
    symbol: '',
    rate: '',
    is_active: false,
    created_at: '',
    updated_at: '', 
};
