<template>
  <div>
    <ul class="daily__box">
      <li v-for="c in persons" :key="c.id" class="daily__item1">
        <div class="daily__header">
          <span>{{ c.header }}</span>
        </div>
        <div class="daily__info">
          <div class="daily__squ1">
            <div class="daily__per1">
              <span>{{ c.coming }}%</span>
            </div>
            <div class="daily__day1">
              <span v-if="c.coming > 30" class="daily__ico">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M17.8 8.79999L13 13.6L9.7 10.3C9.3 9.89999 8.7 9.89999 8.3 10.3L2.3 16.3C1.9 16.7 1.9 17.3 2.3 17.7C2.5 17.9 2.7 18 3 18C3.3 18 3.5 17.9 3.7 17.7L9 12.4L12.3 15.7C12.7 16.1 13.3 16.1 13.7 15.7L19.2 10.2L17.8 8.79999Z" fill="currentColor"/>
                  <path opacity="0.3" d="M22 13.1V7C22 6.4 21.6 6 21 6H14.9L22 13.1Z" fill="currentColor"/>
                </svg>
              </span>
              <span v-else class="daily__nico">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M19.2 13.8L13.7 8.3C13.3 7.9 12.7 7.9 12.3 8.3L9 11.6L3.7 6.3C3.3 5.9 2.7 5.9 2.3 6.3C1.9 6.7 1.9 7.3 2.3 7.7L8.3 13.7C8.7 14.1 9.3 14.1 9.7 13.7L13 10.4L17.8 15.2L19.2 13.8Z" fill="currentColor"/>
                  <path opacity="0.3" d="M22 10.9V17C22 17.6 21.6 18 21 18H14.9L22 10.9Z" fill="currentColor"/>
                </svg>
              </span>
              <span class="daily__text">{{ c.date }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data: () => ({
    persons: [
      {
        coming: 72,
        pending: 26,
        stay: 2,
        date: '13/06/2022',
        header: 'Понедельник'
      },
      {
        coming: 86,
        pending: 62,
        stay: 2,
        date: '14/06/2022',
        header: 'Вторник'
      },
      {
        coming: 78,
        pending: 20,
        stay: 2,
        date: '15/06/2022',
        header: 'Среда'
      },
      {
        coming: 79,
        pending: 19,
        stay: 2,
        date: '16/06/2022',
        header: 'Четверг'
      },
      {
        coming: 90,
        pending: 8,
        stay: 2,
        date: '17/06/2022',
        header: 'Пятница'
      },
      {
        coming: 29,
        pending: 69,
        stay: 2,
        date: '18/06/2022',
        header: 'Суббота'
      },
      {
        coming: 5,
        pending: 93,
        stay: 2,
        date: '19/06/2022',
        header: 'Воскресенье'
      },
    ]
  })
}
</script>

<style>

</style>
