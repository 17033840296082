import {
    i18n
} from '@/utils/i18n';
export const columns = {
    id: {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    car_number: {
        show: true,
        title: i18n.t('message.car_number'),
        sortable: true,
        column: 'car_number'
    },
    car_model: {
        show: false,
        title: i18n.t('message.car_model'),
        sortable: true,
        column: 'car_model'
    },
    car_color: {
        show: false,
        title: i18n.t('message.car_color'),
        sortable: true,
        column: 'car_color'
    },
    driver: {
        show: true,
        title: i18n.t('message.car_driver'),
        sortable: true,
        column: 'driver'
    },
    reason: {
        show: true,
        title: i18n.t('message.reason'),
        sortable: true,
        column: 'reason'
    },
    comment: {
      show: true,
      title: i18n.t('message.comment'),
      sortable: true,
      column: 'comment'
    },
    managed_task: {
      show: true,
      title: i18n.t('message.managed_task'),
      sortable: true,
      column: 'managed_task'
  },
    left_date_time: {
        show: true,
        title: i18n.t('message.left_date_time'),
        sortable: true,
        column: 'left_date_time'
    },

    arrival_date_time: {
        show: true,
        title: i18n.t('message.arrival_date_time'),
        sortable: true,
        column: 'arrival_date_time'
    },

    total_spent_time: {
        show: true,
        title: i18n.t('message.difference'),
        sortable: true,
        column: 'total_spent_time'
    },
    created_at: {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};
