import { i18n } from '@/utils/i18n';
export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    full_name : {
        show: true,
        title: i18n.t('message.nameFull'),
        sortable: true,
        column: 'full_name'
    },
    additional_information : {
        show: true,
        title: i18n.t('message.additional_information'),
        sortable: true,
        column: 'additional_information'
    },    
    total_visit: {
        show: true,
        title: i18n.t('message.total_visit'),
        sortable: true,
        column: 'total_visit'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
