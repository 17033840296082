export const model = {
    id: null,
    name: '',
    // name: {
    //   'uz': '',
    //   'ru': '',
    //   'en': '',
    //   'tr': ''
    // },
    gender_ids: [],
    branch_id:'',
    department_ids:[],
    requirement_id:'',
    min_age: null,
    max_age: null,
    priority_id:'',
    position_id: '',  
    graphic_ids:[],
    limit:'',
    employmentType_id:'',
    language_ids:[],
    skill_ids:[],
    salary_currency_ids:[],
    min_wage: null,
    max_wage: null,
    region_id:'',
    district_id: '',
    description:'',
    created_at: '',
    updated_at: '',
};
