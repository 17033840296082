import { index,staffAttendance,store } from "@/api/staff/attendance";
import { i18n } from '@/utils/i18n';

export const actions = {       

    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {                
                commit("SET_LIST", res.data.result.data.attendances);
                let arr =[];
                let respon = res.data.result.data.attendances;
                respon.forEach((value, index) => {
                    value.device_two =  value.device;
                    value.staff_full_name =  value.staff ? value.staff.name + " " + value.staff.last_name : "";
                    value.department = value.staff  ? value.staff.department ? value.staff.department.name : "" : "";
                    value.branch = value.staff  ? value.staff.branch ? value.staff.branch.name : "" : "";
                    value.company = value.staff  ? value.staff.company ? value.staff.company.name : "" : "";
                    value.punch_state =  value.punch_state;
                    value.punch_time =  value.punch_time;
                    value.device =  value.device?(value.device.device_name) : i18n.t('message.manual');
                    arr.push(value);
                });
                commit("SET_EXCEL", arr);
                commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    staffAttendance({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            staffAttendance(params).then(res => {                
                commit("SET_ATTENDANCE", res.data.result.data.attendances);
                let excel =[];
                let respon = res.data.result.data.attendances;
                respon.map((value, index) => {
                    value.device_two =  value.device;
                    value.staff_full_name =  value.staff ? value.staff.name + " " + value.staff.last_name : "";
                    value.department = value.staff  ? value.staff.department ? value.staff.department.name : "" : "";
                    value.branch = value.staff  ? value.staff.branch ? value.staff.branch.name : "" : "";
                    value.company = value.staff  ? value.staff.company ? value.staff.company.name : "" : "";
                    value.punch_state =  value.punch_state;
                    value.punch_time =  value.punch_time;
                    value.device =  value.device?(value.device.device_name) : i18n.t('message.manual');
                    excel.push(value);
                });
                commit("SET_EXCEL", excel);
                commit("UPDATE_PAGINATION_ATTENDANCE", { key: 'total', value: res.data.result.data.pagination.total });
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
    show({ commit }, id) {
        return new Promise((resolve, reject) => {
            show(id)
                .then(res => {
                    commit('SET_MODEL', res.data.result.data.attendance);
                    resolve(res);
                })
                .catch(err => {
                    reject(err.response.data);
                });
        });
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    updatePaginationAttendance({ commit }, pagination) {
        commit('UPDATE_PAGINATION_ATTENDANCE', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
