import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter);

let routes = [{
        path: '/login',
        component: () => import('@/views/login/index'),
        hidden: true
    },
    {
      name: 'twostep.index',
      path: '/twostep',
      component: () =>
          import ('../views/twostep/index.vue'),
      hidden: true
    },
  //   {
  //     path: '/settingsApp',
  //     component: Layout,
  //     redirect: '/',
  //     children: [{
  //         path: '/',
  //         component: () =>
  //             import('../views/settingApp/form'),
  //         name: 'settingsApp',
  //         meta: { title: 'Настройки Компании' }
  //     }]
  // },
]

/**
 * Load all routes from modulea folder
 */
const request = require.context('./modules', true, /.js/);

request.keys().map(module => {
    routes = routes.concat(request(module).default);
});

const router = new VueRouter({
    routes: routes,
    mode: "history"
})

export default router;
