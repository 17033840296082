<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.company')"
      filterable
      clearable
      :disabled="disabled"
      :size="size"
      class="d-block"
      :multiple="multiple"
      :class="mode ? 'input__day' : 'input__night'"
    >
      <el-option
        v-for="(guestCompany, index) in guestCompanies"
        :key="'guestCompanies-' + index"
        :label="guestCompany.name"
        :value="guestCompany.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    guest_id: {
      default: null,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    id: {
      handler: function () {
        setTimeout(() => {
          this.selected = this.id;
        });
      },
      deep: true,
      immediate: true,
    },
    guest_id: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.selected = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (this.companies && this.companies.length === 0) {
      this.updateInventory();
    }
  },
  computed: {
    ...mapGetters({
      companies: "guestCompany/inventory",
      mode:'MODE'
    }),
    guestCompanies: function () {
      if (!this.guest_id) {
        return this.companies;
      }
      if (this.guest_id) {
        let filteredCompanies= _.filter(this.companies, ["guest_id", this.guest_id]);        
        if(filteredCompanies.length==1)
        {           
            this.dispatch(filteredCompanies[0].id);
            return filteredCompanies;
        }
        return filteredCompanies;
      }
    },
  },
  methods: {
    ...mapActions({
      updateInventory: "guestCompany/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
      let companyFound = _.find(this.companies, ["id", e]);
      if (companyFound) {
        this.$emit("refreshGuestId", companyFound.guest_id);
      }
    },
  },
};
</script>
