export const getters = {
  list: state => state.list,
  excel_data: state => state.excel_data,
  test: state=>state.test,
  dismissed: state=>state.dismissed,
  inventory: state => state.inventory,
  telegramStaff: state => state.telegramStaff,
  model: state => state.model,
  rules: state => state.rules,
  columns: state => state.columns,
  filter: state => state.filter,
  pagination: state => state.pagination,
  sort: state => state.sort,  
};
