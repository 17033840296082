export default [{
    path: '/transaction',
    name: 'transaction',
    meta: {
      layout: 'main'
    },
    component: () => import('@/views/transactions/index')
  }]
  

  