export const getters = {
  list: state => state.list,
  social_chart: state => state.social_chart,
  step_chart: state => state.step_chart,
  step_chart_by_count: state => state.step_chart_by_count,
  inventory: state => state.inventory,
  model: state => state.model,
  rules: state => state.rules,
  columns: state => state.columns,
  index_columns: state=>state.index_columns,
  filter: state => state.filter,
  pagination: state => state.pagination,
  candidate_pagination: state=>state.candidate_pagination,
  sort: state => state.sort,
  vacancy_candidate_list: state=>state.vacancy_candidate_list, 
  
};
