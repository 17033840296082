export const rules = {
    name: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    graphic_type_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    // clock_in: [
    //     { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    // ],
    // clock_out: [
    //     { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    // ],
    // break_start_time: [
    //     { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    // ],
    // break_end_time: [
    //     { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    // ],
   
};
