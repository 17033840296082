import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    branch_id : {
      show: true,
      title: i18n.t('message.branch'),
      sortable: true,
      column: 'branch_id'
  },
    wage : {
        show: true,
        title: i18n.t('message.wage'),
        sortable: true,
        column: 'wage'
    },
    from_date : {
        show: true,
        title: i18n.t('message.from_date'),
        sortable: true,
        column: 'from_date'
    },
    to_date : {
        show: true,
        title: i18n.t('message.to_date'),
        sortable: true,
        column: 'to_date'
    },
    created_at : {
        show: false,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: false,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
