import {index, recalculateStaffMonth} from "@/api/staffCalendar/staffCalendar";

export const actions = {
  index({commit}, params = {}) {
    return new Promise((resolve, reject) => {
      index(params)
        .then(res => {
          commit("SET_LIST", res.data.result.data);
          //console.log("logData", res)
          resolve(res.data.result.data);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  },
  recalculateStaffMonth({commit}, params = {}) {
    return new Promise((resolve, reject) => {
      recalculateStaffMonth(params)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
