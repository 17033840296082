import request from "@/utils/request";

export function index(params) {
  return request({
    url: "/settings/documents",
    method: "get",
    params: params
  });
}

export function inventory(params) {
  return request({
    url: "/settings/document/inventory",
    method: "get",
    params
  });
}

export function show(id) {
  return request({
    url: `/settings/documents/${id}`,
    method: "get"
  });
}

export function store(data) {
  return request({
    url: "/settings/documents",
    method: "post",
    data
  });
}

export function update(data) {
  return request({
    url: `/settings/documents/${data.id}`,
    method: "post",
    data
  });
}

export function destroy(id) {
  return request({
    url: `/settings/documents/${id}`,
    method: "delete"
  });
}

export function createCandidate(data, vacancyUuid) {
  return request({
    url: `/vacancy/${vacancyUuid}`,
    method: "post",
    data
  });
}

export function downloadFile(data) {
  return request({
    url: `settings/documents/download-file/${data.id}`,
    method: "get",
    responseType: "blob"
  });
}
