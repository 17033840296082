import request from '@/utils/request'

export function getZktecoUser(staff_id) {
    return request({
        url: `acms/zkteco/user/${staff_id}`,
        method: 'get'        
    })
}

export function createZktecoUser(data) {
    return request({
        url: `acms/zkteco/create/user`,
        method: 'post',
        timeout:100000,
        data        
    })
}

export function deleteZktecoUser(zkteco_user_id) {
    return request({
        url: `acms/zkteco/delete/user/${zkteco_user_id}`,
        method: 'delete',
        timeout:100000,           
    })
}
