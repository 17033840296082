import request from '@/utils/request'

export function index(params) {
  return request({
      url: '/settings/positions',
      method: 'get',
      params: params
  })
}


export function inventory(params) {
  return request({
      url: '/settings/position/inventory',
      method: 'get',
      params
  })
}
export function getTariffCategory(params) {
  return request({
      url: '/settings/position/tariff-categories',
      method: 'get',
      params
  })
}

export function show(id) {
  return request({
      url: `/settings/positions/${id}`,
      method: 'get'
  })
}

export function store(data) {
  return request({
      url: '/settings/positions',
      method: 'post',
      data
  })
}

export function update(data) {
  return request({
      url: `/settings/positions/${data.id}`,
      method: 'put',
      data
  })
}

export function destroy(id) {
  return request({
      url: `/settings/positions/${id}`,
      method: 'delete',
  })
}
