import { i18n } from '@/utils/i18n';
export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    number:{
        show: true,
        title: i18n.t('message.car_number'),
        sortable: true,
        column: 'number'
    },
    model:{
        show: true,
        title: i18n.t('message.car_model'),
        sortable: true,
        column: 'model'
    },  
    color:{
        show: true,
        title: i18n.t('message.car_color'),
        sortable: true,
        column: 'color'
    },     
    staff:{
        show: true,
        title: i18n.t('message.car_driver'),
        sortable: true,
        column: 'staff'
    },
    driver:{
      show: true,
      title: i18n.t('message.car_driver'),
      sortable: true,
      column: 'staff'
    },
    name:{
        show: true,
        title: i18n.t('message.line'),
        sortable: true,
        column: 'name'
    },    
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }
};
