import {
  i18n
} from '@/utils/i18n';

export const columns = {
  id: {
    show: true,
    title: i18n.t('message.n'),
    sortable: true,
    column: 'id'
  },
  trackable_type: {
    show: true,
    title: i18n.t('message.nameGiven'),
    sortable: true,
    column: 'trackable_type'
  },
  trackable_id: {
    show: true,
    title: i18n.t('message.id'),
    sortable: true,
    column: 'trackable_id'
  },
  method_type: {
    show: true,
    title: i18n.t('message.method_type'),
    sortable: true,
    column: 'method_type'
  },
  user: {
    show: true,
    title: i18n.t('message.user'),
    sortable: true,
    column: 'user'
  },
  ip_address: {
    show: true,
    title: i18n.t('message.ip_address'),
    sortable: true,
    column: 'ip_address'
  },
  browser_type: {
    show: true,
    title: i18n.t('message.browser_type'),
    sortable: true,
    column: 'browser_type'
  },
  created_at: {
    show: true,
    title: i18n.t('message.created_at'),
    sortable: true,
    column: 'created_at'
  },
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: 'settings'
  }


};
