<template>
    <el-select :class="mode ? 'input__day' : 'input__night'" :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.user')" filterable clearable :size="size" class="d-block">
        <el-option v-for="(user,index) in users" :key="'users-' + index" :label="user.name+' '+user.last_name" :value="user.id"></el-option>
    </el-select>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import {i18n} from '@/utils/i18n';
    export default {
        name:'users',       
        props: {
          size: {
              default: 'small'
          },
          placeholder: {
              default: null,
          }        
        },        
        mounted() {
            if (this.users && this.users.length === 0) this.updateInventory()
        },
        data() {
          return {
            selected: null,
          }
        },
        computed: {
            ...mapGetters({
                users: 'users/inventory',
                mode:'MODE'
            })
        },
        methods: {
            ...mapActions({
                updateInventory: 'users/inventory'
            }),
            dispatch(e) {
              this.$emit('input', e)
              this.selected = e
            }   
            
        },
    }
</script>
