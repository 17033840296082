export const uz_messages = {
  yes: "Ha",
  no: "Yo'q",
  n: "№",
  tr: "T/r",
  create: "Yaratish",
  age: "Yosh",
  limit: "Ishtirokchilar soni",
  new_m: "Yangi {m}",
  edit_m: "O'zgartirish  {m}",
  edit: "O'zgartirish",
  main: "Bosh sahifa",
  search: "Topish",
  gender: "Jins",
  position_2: "Lavozim (2-si)",
  department: "Bo'limlar",
  department_: "Bo'lim",
  department_y: "Bo'limlar",
  settings: "Sozlamalar",
  datas: "Sana",
  full_name: "Ism va familiya",
  created_at: "Yaratilgan sana",
  updated_at: "O'zgartirilgan sana",
  name: "Ism",
  nameGiven: "Nomi",
  nameFull: "Ism",
  requirements: "Talablar",
  requirements_y: "Talablar",
  reason: "Sabab",
  reasontype: "Sabab turi",
  staffAssentReason: "Izn",
  reason_y: "Sabablar",
  priority: "Ustuvorligi",
  priority_y: "Ustuvorliklar", /////
  position: "Lavozim",
  position_p: "Lavozimlar",
  graphicType: "Grafik turi",
  graphicType_p: "Grafiklar turi",
  company: "Kompaniya",
  company_y: "Kompaniyalar",
  language: "Tillarni bilishi",
  language2: "Tillarni bilishi",
  language_y: "Tillarni bilishi",
  currency: "Valyuta",
  currency_y: "Valyuta",
  salary_currency: "Valyuta (maosh)",
  salary_currency_y: "Valyuta (maosh)",
  branch: "Filial",
  branch_a: "Filial",
  branch_parental: "Bosh filial",
  skill: "Ko'nikmalar",
  skill_y: "Ko'nikma",
  total_working_day: "Ish haftasi",
  graphic: "Ish grafigi",
  language_graphic_type: "Iltimos, nom kiriting",
  employmentType: "Bandlik turi",
  province: "Shahar",
  region: "Shahar",
  region_: "Mintaqa",
  vacancy: "Bo'sh ish o'rinlari",
  last_name: "Familiya",
  middle_name: "Sharif",
  date_of_birth: "Tug'ilgan sana",
  phone_number: "Telefon",
  email: "Elektron pochta",
  status: "Holat",
  role: "Rollar",
  security_location: "Hudud",
  securityLocations_y: "Hudud",
  deviceType: "Qurilma turi",
  deviceType_y: "Qurilmalar turi",
  department_title: "Bo'lim va bo'linmalar",
  parent_department_id: "Bosh bo'lim",
  min_wage_y: "Min. maosh",
  max_wage_y: "Maks. maosh",
  phone_number_y: "Telefon",
  summary: "Rezyume",
  UploadFile: "Faylni yuklash",
  UploadFileText: "500 KB dan kichik jpg/png fayl",
  serial_number: "Seriya raqami",
  ip_address: "IP manzili",
  device: "Qurilmalar",
  device_y: "Qurilma",
  income: "Daromad darajasi",
  work_type: "Xodim holati",
  staff_work_type: "Ish turi",
  district_id: "Hudud ",
  district: "Hudud",
  nation: "Millat",
  nation_y: "Millat",
  code: "Kod",
  okurs: "Teskari kurs",
  profile: "Profil",
  dateAdoption: "Qabul qilish sanasi",
  staffTest: "Sinovdagi",
  operating: "Ishlayotgan",
  dismissed: "Ishdan bo'shatilgan",
  save: "Saqlash",
  close: "Yopish",
  candidates: "Nomzodlar",
  find: "Qidiruv",
  delete: "O'chirish",
  daily: "Kun",
  from: "dan",
  before: "gacha",
  dinner: "Tushlik",
  users: "Foydalanuvchilar",
  user: "Foydalanuvchi",
  password: "Parol",
  confirm_password: "Parolni qaytadan kiriting",
  finance: "Moliya",
  client: "Mijoz",
  access_rights: "Kirish huquqlari",
  active: "Faol",
  inactive: "Faol emas",
  working_hours_report: "Ish vaqti hisoboti",
  age: "Yosh",
  nv_monitoring: "HV monitoringi",
  new_incoming_payment: "Yangi kiruvchi to'lov",
  incoming: "Kirim",
  outgoing: "Chiqim",
  employees: "Xodimlar",
  payment_type: "To'lov turi",
  amount: "Miqdor",
  check: "Hisob",
  check_a: "Hisoblar",
  salary_type: "Maosh turi",
  salary: "Maosh",
  bank: "Bank",
  checking_account: "Hisob raqami",
  expiration_date: "Tugash muddati",
  comment: "Izoh",
  overtime: "Qo'shimcha ish haqi",
  total: "Jami:",
  being_late: "Kechikishlar",
  other_penalties: "Boshqa jarimalar",
  penalties: "Jarimalar",
  on_hands: "Naqd",
  passport_number: "Pasport seriyasi",
  passport_issued_by: "Pasport kim tomonidan berilgan",
  passport_valid_until: "Pasport amal qilish sanasi",
  inn: "STIR",
  telegram: "Telegram",
  telegram_staff: "Telegram Staff",
  job: "Ish",
  hiring_date: "Ishga olingan sana",
  manager: "Rahbar",
  allow_th_employee_to_access_the_system:
    "Xodimga tizimga kirishga ruxsat berish.",
  allow_salary: "Maosh",
  add_candidates: "Nomzodlarni qo'shish",
  description: "Tavsif",
  photo: "Foto",
  min: "minimal",
  max: "maksimal",
  recruiting: "Rekruting",
  desktop: "Ish stoli",
  indicators: "Ko'rsatkichlar",
  journal: "Jurnal",
  quit: "Chiqish",
  branch_name: "Filial nomi",
  mfo: "MFО",
  number: "Raqam",
  account: "Hisob raqami",
  card_number: "Karta raqami",
  penalty_name: "Jarima nomi",
  bonus_name: "Bonus nomi",
  payment_date: "To'lov sanasi",
  adaptation: "Moslashuv",
  agreement_employees: "Shartnomalar(Xodimlar)",
  login: "Kirish",
  permission: "Ruxsatlar",
  synchronization: "Sinxronlashtirish",
  tests: "Testlar",
  wage: "Maosh",
  dafault: "Standart",
  real_amount: "Haqiqiy miqdor",
  bonus_amount: "Bonus miqdori",
  payment_for: "Davr uchun to'lov",
  family_location: "Oilaviy holati",
  url: "Havola",
  age: "Yosh",
  wages: "Maosh",
  complete_form: "Shaklni to'ldiring",
  KPP: "KPP ",
  guest: "Mehmon",
  card: "Avtomobil",
  copyUrl: "URLni nusxalash",
  vacancyNotAvailable: "Ish joyi mavjud emas",
  fire_staff: "Ishdan bo'shatilganlar",
  user_monitoring: "Foydalanuvchilar monitoringi",
  car_driver: "Asosiy haydovchi",
  new_operation: "Yangi operatsion tizim",
  update_operation: "Operatsion tizimni o'zgartirish",
  transport: "Transport",
  arrival_date_time: "Kelish sanasi va vaqti",
  arrival_date: "Kelish sanasi",
  arrival_time: "Kelish vaqti",
  difference: "Farq",
  managed_task: "Vazifani boshqarish",
  employee_entrance: "Xodim kirishi",
  employee_exit: "Xodim chiqishi",
  entrance: "Kirish",
  exit: "Chiqish",
  type: "Turi",
  district: "Tuman",
  min_wage: "Minimal maosh",
  max_wage: "Maksimal maosh",
  min_age: "Minimal yosh",
  max_age: "Maksimal yosh",
  description: "Tavsif",
  symbol: "Belgi",
  staff: "Xodimlar",
  active: "Faol",
  inactive: "Faol emas",
  role_is_not_set: "Rol tayinlanmagan ",
  new: "Yangi",
  // amount: 'суммы',
  amount_a: "Miqdor",
  comment: "Izoh",
  attendance_type: "Ishtirok etish turi",
  port: "Port",
  car: "Avtomobil",
  guest_coming_reason: "Kimning oldiga keldi?",
  visit_date: "Kelish sanasi",
  visit_date_time: "Kelish sanasi va vaqti",
  visit_time: "Kelish vaqti ",
  left_date: "Chiqish sanasi",
  left_date_time: "Chiqish sanasi va vaqti",
  left_time: "Chiqish vaqti",
  car_number: "Avtomobil raqami",
  car_color: "Avtomobil rangi",
  car_type: "Avtomobil turi",
  car_model: "Avtomobil modeli",
  additional_information: "Qo'shimcha ma'lumot",
  add_guest: "Mehmon qo'shish",
  add_company: "Kompaniya qo'shish",
  address: "Manzil",
  add_car: "Avtomobil qo'shish",
  add_phone_number: "Telefon qo'shish",
  visitor: "Mehmon",
  update_visitor: "Mehmonni o'zgartirish",
  total_visit: "Jami tashriflar",
  car_information: "Avtomobil haqida ma'lumot",
  supported_formats: "Fayl formati:  doc, docx, pdf, jpg, jpeg, gif, svg, png",
  size_limit_5: "Fayl hajmi: 5 MB",
  candidates: "Nomzodlar",
  attendance: "Davomat",
  time: "Vaqt",
  attendance_type: "Kirish/Chiqish",
  img: "Foto",
  verfired_type: "Tasdiqlash turi",
  today: "Bugun",
  refresh: "O'zgartirish",
  exit_target: "Chiqishdan maqsad",
  expected_emp: "Kirish rejasi",
  chel: "Odam",
  factual_emp: "Kirish fakti",
  transport_type: "Avtomobil turi",
  client_transport: "Hamkor avtomobili",
  company_transport: "Kompaniya avtomobili",
  product: "Mahsulot",
  product_name: "Mahsulot nomi",
  quantity: "Miqdori",
  measurement_type: "O'lchov birligi",
  copy: "Nusxalash",
  add_new: "Yangi qo'shish",
  shipping_letter: "Yuk chiqarish xati",
  user_activity: "Foydalanuvchi faolligi",
  id: "Identifikator",
  method_type: "Usul turi",
  browser_type: "Brauzer turi",
  social: "Manba",
  where_know: "Qayerdan bildingiz",
  line: "Liniya",
  accept: "Qabul qilish",
  education: "Ma'lumoti",
  header: "Mavzu",
  title: "Matn",
  periot: "Avans olish sanasi",
  Tuesday: "Seshanba",
  Wednesday: "Chorshanba",
  Thursday: "Payshanba",
  Friday: "Juma",
  Saturday: "Shanba",
  Sunday: "Yakshanba",
  Monday: "Dushanba",
  "settings profile": "Profil sozlamalari",
  settingCompany: "Kompaniya ma'lumoti",
  logout: "Chiqish",
  category: "Turkum",
  categories: "Turkumlar",
  telegram_managers: "Telegram xabari",
  telegram_manager: "Telegram xabari",
  vacation: "Ta'til hisobi",
  children: "Bolalar",
  newcomestaffs: "Yaqinda ishga olinganlar",
  completestaffprofile: "Profil to'ldirilganlik foizi",
  staffTests: "Sinov davri",
  operatings: "Ishlayotgan",
  dismisseds: "Ishdan bo'shatilgan",
  average_age: "O'rtacha yosh",
  arrivals_and_departures: "Kelish va ketish nisbati",
  accepted_CV: "Qabul qilingan rezyumelar",
  total_qty: "Jami miqdori",
  present: "Kelganlar",
  missing: "Kelmaganlar",
  past_birthdays: "O'tgan tug'ilgan kunlar",
  today_birthdays: "Bugungi tug'ilgan kunlar",
  expected_birthdays: "Kutilayotgan tug'ilgan kunlar",
  count_gender: "Kompaniyadagi erkaklar va ayollar soni",
  not_data: "Hozircha ma'lumot yo'q",
  reasons_for_layoffs: "Ishdan bo'shatilish sabablari ",
  official_and_unofficial: "Kompaniyadagi rasmiy va norasmiy xodimlari soni",
  faceidregistration: "Face ID'da ro'yxatdan o'tish",
  registered: "Ro'yxatga olingan",
  not_registered: "Ro'yxatdan o'tmagan",
  compleinProfile: "Filiallar bo'yicha to'ldirilgan anketalar, %",
  worksPlace: "Ish tajribasi",
  salary_by_branches: "Filiallar bo'yicha o'rtacha maosh",
  districtByStaff: "Hududlar bo'yicha xodimlar soni",
  will_arrive: "Keladi",
  not_come: "Kelmaydi",
  answer_expected: "Javob kutilmoqda",
  assign_role: "Rol tayinlash",
  excel: "Excel",
  weekly: "Hafta",
  monthly: "Oy",
  yearly: "Yil",
  coming: "Kelish",
  pending: "Kutish jarayonida",
  additional_rest: "Qo'shimcha ish",
  o_salary: "Maosh",
  bonuses: "Bonuslar",
  avance: "Avans",
  tms: "Kirish|Ketish YUK",
  employee_kpp: "Xodimlar Kelish|Ketishi",
  operation: "Operatsion tizim",
  company_machines: "Kompaniya mashinalari",
  leave_requests: "Ruhsat so'rash",
  take_assents: "Ruhsat so'rash",
  report_card: "Tabel",
  structure: "Tuzilishi",
  additional_work: "Qo'shimcha ish",
  additional_works: "Qo'shimcha ish",
  complaint: "Shikoyatlar",
  calendar: "Kalendar",
  fio: "F.I.Sh",
  general_working_day: "Umumiy ish kuni",
  general_working_hour: "Umumiy ish soati",
  records_of_the_month: "Kunlik davomat",
  working_day: "Ish kuni",
  working_day_hour: "Ish vaqti",
  working_month_hour: "Standart ish vaqti",
  unrevealed: "Kelmaganlar",
  waiting: "Kutilmoqda",
  received: "Qabul qilindi",
  denied: "Rad etildi",
  all: "Hamma",
  those_present: "Hozirda borlar",
  released: "Chiqib ketganlar",
  missing: "Kelmaganlar",
  with_reason: "Sababli",
  without_reason: "Sababsiz",
  online: "Onlayn",
  confirm: "Tasdiqlangan",
  approved: "Tasdiqlangan",
  rejected: "Rad etilgan",
  allowed: "Ruxsat berilgan",
  date_time: "Sana va vaqt",
  performance_type: "Ish turi",
  qty: "Miqdor",
  details: "Tafsilotlar",
  permis: "Ruxsat",
  parental_permission: "Bosh ruxsat",
  translated_resolution: "Almashtirilgan ruxsat",
  children_permission: "Quyi ruxsat",
  children_translated_permission: "Quyi ruxsat tarjimasi",
  telegram_password: "Telegram parol",
  last_active: "Oxirgi aktivlik",
  subordinates: "Qo'l ostidagilar",
  document: "Hujjatlar",
  file: "Fayllar",
  additional_information: "Qo'shimcha ma'lumot",
  work_history: "Ish tarixi",
  cancel: "Bekor qilish",
  personal: "Shaxsiy",
  limit_work_time: "Ish me'yori (soat)",
  actual_working_time: "Ishlagan vaqti (soat)",
  salary_rotation: "Maosh va rotatsiyalar",
  rotation: "Rotatsiyalar",
  quality_control: "Sifat nazorati",
  process: "Jarayon",
  name_process: "Jarayon nomi",
  norms: "Me'yor",
  fact: "Fakt",
  assigned_salary: "Belgilangan maosh",
  last_month: "O'tgan oy",
  current_month: "Joriy oy",
  request_time: "So'rov vaqti",
  licenses_and_certificates: "Litsenziya va sertifikatlar",
  gave_out: "Berdi",
  issued: "Berilgan",
  release_date: "Olingan sana",
  health_information: "Sog'ligi haqida ma'lumotlar",
  growth: "Bo'yi",
  weight: "Vazni",
  blood_type: "Qon guruhi",
  locations_health_information: "Sog'ligi haqida ma'lumotlar",
  foreign_languages: "Xorijiy tillar",
  degree: "Daraja",
  lang: "Til",
  mobil_number: "Mobil telefoni",
  work_phone: "Ish telefoni",
  facebook_url: "Facebook URL:",
  instagram_url: "Instagram URL:",
  twitter_url: "Twitter URL:",
  linkedIn_url: "LinkedIn URL:",
  telegram_username: "Telegram foydalanuvchisi nomi",
  tiktok_username: "TikTok foydalanuvchisi nomi",
  educational_institution: "O'quv muassasasi",
  faculty: "Fakultet",
  beginning_date: "Boshlanish sanasi",
  expiration_date: "Tugash sanasi",
  skill: "Ko'nikma",
  not_coming_day: "Kelmagan kun",
  hours_or_more: "{m} yoki undan ko'proq",
  less_than_hours: "{m} dan kam",
  day_absence: "Dam olish kuni",
  not_come_work_day: "Ishga kelmagan kun",
  complaints: "Shikoyatlar",
  subject_complaint: "Shikoyat mavzusi",
  izn: "Izn",
  date_of_departure: "Ketish sanasi",
  place_work: "Ish joylari",
  place_work_name: "Ish joyining nomi",
  data_from_to: "Sanalar",
  display_and_wallpaper: "Boshqaruv paneli va ekran",
  dynamic_wallpaper: "Dinamik fon",
  notes: "Eslatmalar",
  from_data: "Boshlanish sanasi",
  to_data: "Tugash sanasi",
  add_document: "Hujjat qo'shish",
  labor_contract: "Mehnat shartnomasi",
  material_liability: "Moddiy javobgarlik",
  confidentiality_agreement: "Maxfiylik shartnomasi",
  employment_contract: "Mehnat shartnomasini bekor qilish",
  finance_training: "Moliyaviy trening",
  plastic_card: "Plastik karta",
  card_number: "Karta raqami",
  date_of_expiry: "Amal qilish sanasi",
  card_organ: "Bank",
  amount_per_hour: "Soatlik miqdor",
  unofficial_amount: "Norasmiy miqdor",
  official_amount: "Rasmiy miqdor",
  type_salary: "Maosh turi",
  amount_money: "Pul miqdori",
  in_hour: "Vaqt",
  access_control: "Kirish nazorati",
  id_employee: "GOHR ID",
  contact: "Kontakt",
  add_skill: "Malaka qo'shish",
  Level: "Daraja",
  // =================
  sort_panel: "Boshqaruv paneli",
  country_passport: "Fuqaroligi",
  Send_Manager: "Menejerga yuborish",
  departure_time: "Ketish vaqti",
  access_time: "Kirish vaqti",
  model: "Modelli",
  izn_password: "Botda registratsiya paroli",
  schedule: "Jadval",
  new_version: "Yangi versiya",
  old_version: "Eski versiya",
  causes_operating_system: "Operatsion tizim sabablari",
  active_machines: "Kompaniyaning ko'p ishlatiladigan mashinalari",
  visitors_to_company_employees: "Kompaniya xodimlariga kelgan mehmonlar",
  number_of_visitors_periods: "Davrlar bo'yicha mehmonlar soni",
  late_to_works: "Kech qolganlar",
  coming_date_time: "Kelish kuni va vaqti",
  coming_time: "Kelish vaqti",
  in_count: "Kirishlar soni",
  out_count: "Chiqishlar soni",
  total_toilet_minuts: "Umumiy vaqt",
  info_tms_statistic: "Oxirgi YUK kirish|chiqish",
  info_toilet_statistic: "Top-20ta hojatxonaga tashrif buyuruvchilar",
  director: "Direktor",
  country: "Mamlakat",
  logo: "Logotip",
  web_site: "Veb-sayt",
  explanatoryLetter: "Tushuntirish xati",
  explanatory_letters: "Tushuntirish xati",
  application_letter: "Ariza",
  application: "Ariza",
  applications: "Arizalar",
  active_works: "Faol xodim|Obod hudud",
  active_work: "Faol xodim|Obod hudud",
  active_person: "Faol xodim",
  prosperous_place: "Obod hudud",
  parent_company_id: "Bosh kompaniya",
  manual: "Rahbariyat tomonidan yaratilgan",
  leader: "Direktor",
  numeric_currency_code: "Valyutaning raqamli kodi",
  letter_currency_code: "Valyutaning harfli kodi",
  payed: "To'langan",
  advance_salary: "Avans",
  advance_salaries: "Avanslar",
  advance_request: "Avans so'rovlari",
  pentaly_salary: "Jarimalar",
  prizes: "Bonuslar",
  hour: "s",
  minut: "d",
  dateuvalen: "Ishdan bo'shatilgan sana",
  department_full:
    "Bu bo'limda ishchi o'rni to'lgan yoki ish o'rinlari soni ko'rsatilmagan!",
  enoughStaff: "Xodim yetishmovchiligi",
  in_work: "Kelganlar",
  not_come_work: "Kelmaganlar",
  sick_leave: "Kasal",
  sick_leaves: "Kasallik ta'tili",
  on_vacation: "Ta'tilda",
  business_trip: "Ish safarida",
  late_to_work: "Kech qolganlar",
  accepted_take_assent: "Izn olganlar",
  not_reason: "Sababsiz kelmaganlar",
  start_date: "Boshlanish sanasi",
  end_date: "Tugash sanasi",
  start_time: "Boshlanish vaqti",
  end_time: "Tugash vaqti",
  organizations: "Tashkilot",
  responsible: "Mas'ul",
  business_travelers: "Ish safariga ketayotganlar",
  business_trip_purpose: "Safar maqsadi",
  business_trip_tasks: "Safardagi vazifalar",
  type_expense: "Xarajat turi",
  type_expenses: "Xarajatlar turlari",
  payments_expense: "Xarajat uchun to'lov",
  payments_expenses: "Xarajatlar uchun to'lovlar",
  business_trips: "Ish safarlari",
  Break: "Tanaffus",
  sanction: "Jarimalar",
  new_sanctions: "Yangi jarima",
  sanction_name: "Sanksiya nomi",
  dosnt_have_salary: "Maoshi kiritilmaganlar",
  have_salary: "Maoshi kiritilganlar",
  value: "Qiymat",
  percent: "Foiz",
  value_type: "Qiymat turi",
  bonus_penalty_reasons: "Jarimalar|bonuslar sababi",
  work_times: "Ish soatlari",
  online_works: "Online ish",
  vacation_managements: "Ta'til boshqaruvi",
  vacation_management: "Ta'til boshqaruvi",
  accept_vacation_managment: "Ta'til oyi",
  not_attached: "Belgilanmagan",
  pnfl: "JSHSHIR",
  vacation_graphics: "Ta'til grafiklari",
  vacation_graphic: "Ta'til grafigi",
  official_date: "Rasmiylashtirish sanasi",
  back: "Chiqish",
  pinfl: "JSHSHIR",
  send_channel: "Kanal jo'natish",
  vacation_planes: "Ta'til rejalari",
  anecdotes: "Latifalar",
  anecdote: "Latifa",
  content: "Mazmun",
  request: "So'rovlar",
  fixed: "Belgilangan maosh",
  hourly: "Soatbay maosh",
  income_tax: "Daromad solig'i",
  start_date_time: "Boshlanish vaqti va sanasi",
  end_date_time: "Tugash vaqti va sanasi",
  settingApp: "GOHR sozlamalari",
  holiday_work_contribution: "Bayram kunlari to'lovi",
  question_category_list: "Savol toifasi",
  question_direction_list: "Savol yo'nalishi",
  question_type_list: "Savol turi",
  validator: "Tasdiqlovchi",
  telegram_quiz: "Telegram viktorina/test",
  started_date: "Boshlanish sanasi",
  point: "Ball",
  questions: "Savollar",
  question: "Savol",
  quiz_plan: "Viktorina rejasi",
  daly_question_count: "Kundalik savollar soni",
  from_date: "Boshlanish sanasi",
  to_date: "Tugash sanasi",
  send_question_time: "Jo'natish vaqti",
  what_for: "Nima uchun",
  media_type: "Media turi",
  closed: "Tugallangan",
  started: "Tugallanmagan",
  sanction_type: "Sanksiya turi",
  sanction_type_items: "Sanksiya turi elementi",
  fix: "To'g'irlash",
  create_sanction_late: "Yaratilgan vaqti (daqiqa)",
  is_free: "Erkin ish grafigi",
  by_connecting_to_the_device: "Uskunaga ulanganda",
  in_vacation: "Ta'tilda",
  lay_off_date: "Ishdan bo'shatish sanasi",
  application_dismissals: "Ishdan bo'shash arizasi",
  send_acceptor: "Telegram'dan jo'natish",
  inventories: "Inventar",
  inventory: "Inventar",
  inventory_categories: "Inventar toifasi",
  inventory_category: "Inventar toifasi",
  all_amount: "Umumiy miqdor",
  online_or_ofline: "Masofadan va/yoki Qo'shimcha",
  all_work_days: "Jami ish kunlari",
  normal_salary: "Norma bo'yicha maosh",
  transport_salary: "Transport harajatlari va bonuslar",
  hourly_salary: "Soatbay maosh",
  all_paid_salary: "Jami to'lanishi kerak",
  "Общие сохраненные": "Jami saqlanganlar",
  date: "Avans berish sanasi",
  day_count: "Kun",
  monthly_limit_work_day: "Oylik ish kuni me'yori",
  monthly_limit_work_hour: "Oylik ish soati me'yori",
  plane_graphic: "Ish grafigi rejasi",
  logout_question: "Hisobdan chiqishingizga ishonchingiz komilmi?",
  operation_canceled: "Operatsiya bekor qilindi!",
  device_count_type: "Qurilmalar soni turi",
  date_uvalen_day_count: "Ishdan bo`shatilish muddati",
  annual_medical_leave: "1-yillik tibbiy tatil kuni",
  oficial_salary: "Of / S",
  un_oficial_salary: "N / S",
  all_salary: "Barcha / S",
  work_day_count: "Ish kuni",
  average_work_time: "Ish soati",
  mounth_limit: "Standart <br/>ish vaqti",
  all_work_time: "Umumiy<br/> ish vaqti",
  online_and_additional_works:
    "<div >Masofadan  yoki<br/> Qo'shimcha ish</div>",
  all_worked_day: "Jami ish kuni",
  per_hour: "Soatbay maosh",
  normal_pay_salary: "Norma buyicha<br/> ish xaki",
  all_pay_salary: "Jami<br/> to'lanishi kerak",
  all_must_paying_salary: "Jami to'lanishi <br/> kerak",
  this_month_vacation: "Shu oyda ta`tilga chiqadiganlar",
  next_month_vacation: "Kelasi oyda ta`tilga chiqadiganlar",
  applicationType: "Ariza turi",
  seer: "Xabardor xodim",
  whose_side: "Xarajat kim tomonidan",
  show_position: "Ariza shapkasida lavozim ko'rinsin",
  tariff_category: "Тарифный разряд",
  tariff_categories: "Тарифный разряд",
  coefficient: "Разряд",
  minimum_wages: "Минимальная оплата труда",
  minimum_wage: "Минимальная оплата труда",
  staff_business_trips: "Xizmat safari so'rovlari",
  Испытательные: "Sinovda",
  Действующие: "Ishlayotgan",
  Уволенные: "Ishdan bo'shatilgan",
  Женский: "Ayol",
  Мужской: "Erkak",
  Официальный: "Rasmiy",
  Неофициальный: "Norasmiy",
  Оффлайн: "Ofisdan ishlash",
  Дистанционное: "Masofadan",
  "Сдельная работа": "Yarim ish kuni",
  Активный: "Faol",
  Неактивный: "Faol emas",
  Наличный: "Naqd pul",
  Пластик: "Plastik",
  Банковский: "Bank",
  open_vacancy: "Ochiq vakansiyalar",
  additional_work_salary: "Qo'shimcha ish xaqi",
  period: "Davr",
  this_week: "Shu haftaga",
  for_today: "Bugunga",
  for_tomorrow: "Ertaga",
  welcome: "Xush kelibsiz!",
  recalculateMonth: "Oyni qayta hisoblash"

};
