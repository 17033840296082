export const model = {
  id: null,
  device_name: '',
  serial_number:'', 
  device_type_id:'',
  security_location_id:'',
  status_id: null,
  created_at: '',
  updated_at: ''  
};
