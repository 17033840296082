import { i18n } from '@/utils/i18n';
export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    staff : {
        show: true,
        title: i18n.t('message.staff'),
        sortable: true,
        column: 'staff'
    },
    reason : {
      show: true,
      title: i18n.t('message.reason'),
      sortable: true,
      column: 'reason'
  },  
    comment : {
        show: true,
        title: i18n.t('message.comment'),
        sortable: true,
        column: 'comment'
    },  
     
    coming_date_time: {
        show: true,
        title: i18n.t('message.arrival_date_time'),
        sortable: true,
        column: 'coming_date_time'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
