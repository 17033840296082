import request from '@/utils/request'

export function index(params) {
  return request({
      url: '/settings/tariff-categories',
      method: 'get',
      params: params
  })
}


export function inventory(params) {
  return request({
      url: '/settings/tariff-category/inventory',
      method: 'get',
      params
  })
}

export function show(id) {
  return request({
      url: `/settings/tariff-categories/${id}`,
      method: 'get'
  })
}
export function getMinimumWage(branch_id) {
  return request({
      url: `/settings/tariff-category/minimum-wage/${branch_id}`,
      method: 'get'
  })
}

export function store(data) {
  return request({
      url: '/settings/tariff-categories',
      method: 'post',
      data
  })
}

export function update(data) {
  return request({
      url: `/settings/tariff-categories/${data.id}`,
      method: 'put',
      data
  })
}

export function destroy(id) {
  return request({
      url: `/settings/tariff-categories/${id}`,
      method: 'delete',
  })
}
