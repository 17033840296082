import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    name : {
        show: true,
        title: i18n.t('message.nameGiven'),
        sortable: true,
        column: 'name'
    },
    
    branch_id : {
        show: true,
        title: i18n.t('message.branch'),
        sortable: true,
        column: 'branch_id'
    },
    number_staff : {
      show: true,
      title: i18n.t('message.quantity'),
      sortable: true,
      column: 'number_staff'
  },
    parent_department_id : {
        show: true,
        title: i18n.t('message.parent_department_id'),
        sortable: true,
        column: 'parent_department_id'
    },
    staff : {
        show: true,
        title: i18n.t('message.staff'),
        sortable: true,
        column: 'staff'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
