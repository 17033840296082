import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/kpp/operations',
        method: 'get',
        params: params
    })
}

export function inventory(params) {
    return request({
        url: '/kpp/operation/inventory',
        method: 'get',
        params
    })
}
export function reasons(params) {
  return request({
      url: '/kpp/operation/get-reasons',
      method: 'get',
      params
  })
}



export function show(id) {
    return request({
        url: `/kpp/operations/${id}`,
        method: 'get'
    })
}

export function store(data) {
    return request({
        url: '/kpp/operations',
        method: 'post',
        data
    })
}

export function update(data) {
    return request({
        url: `/kpp/operations/${data.id}`,
        method: 'put',
        data
    })
}

export function destroy(id) {
    return request({
        url: `/kpp/operations/${id}`,
        method: 'delete',
    })
}
export function updateArrivalDateTimes(data) {
    return request({
        url: `/kpp/operation/update_arrival_date_times/${data.id}`,
        method: 'put',
        data
    })
}
