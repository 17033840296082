<template>
  <div class="login">
    <el-row>
      <el-col :span="10">
        <div class="login__left">
          <div class="login__box">
            <div class="login__title">
              <img src="/img/gohrnew.png" alt="">
              <h4>Что такое GOMAX ?</h4>
              <span>Enterprise Resource Planning - планирование ресурсов предприятия!</span>
              <span>Это система автоматизации и оптимизации бизнес-процессов и процессов производства.</span>
            </div>
            <div class="login__footer">
              <img src="https://preview.keenthemes.com/good/assets/media/illustrations/sketchy-1/2-dark.png" alt="">
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="14">
        <div class="login__right">
          <div class="login__center">
            <div class="login__size">
              <div class="sign__title">
                <h5>Введите код-пароль</h5>
              </div>
              <div class="login__input" :class="wrong ? 'wrong__input' : ''">
                <span @click="focusInput" :class="[{ active__text: password.length >= 1 ? true : focused}, wrong ? 'wrong__text' : '']">Код доступа</span>
                <input
                      :class="[{ active__input: password.length >= 1 ? true : focused }]"
                      v-model="password"
                      @keyup.enter="submitHandler"
                      id="typeBox"
                      ref="typeBox"
                      @focus="focused = true"
                      @blur="focused = false"
                      type="text"
                >
              </div>
               <p v-if="wrong" class="text-red-500 text-xs my-0 wrong__paswword">Неверный код-пароль</p>

              <div class="login__submit">
                <a @click="submitHandler">ПОДВЕРДИТЬ</a>
              </div>
              <div class="log__out">
                <span>Если Вы не помните код-пароль, Вы можете 
                  <a class="log__outtext" @click="logout">{{ $t("message.logout") }}</a>
                  и авторизавоться заново.
                </span>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import LockScreenMixin from '../../utils/mixins/LockScreenMixin'
import bcrypt from "bcryptjs";
import { mapActions } from "vuex";
export default {
    name: 'login',
    props: {showLock: Boolean},
    mixins: [LockScreenMixin],
    data: () => ({
        password_error: false,
        password: '',
        focused: false,
        watchTime: "",
        intervalId: "",
        wrong: false
    }),
    delimiters: ["[[", "]]"],
    mounted() {
        this.focusInput()
    },
    watch: {
        password () {
            this.wrong = false
        }
    },
    methods: {
      ...mapActions({
          userLogOut: "auth/logout",
      }),
      focusInput () {
          const self = this;
          setTimeout(function () {
              self.$refs.typeBox.focus()
          }, 1)
      },
      submitHandler () {
          if(this.password_error){
              this.password_error = false;

          }else{
              if(bcrypt.compareSync(this.password, this.lockPassword)){
                  this.wrong = false
                  this.locked = false;
                  this.TRIGGER_LOCK({ status: false });
                  this.password = '';
                  this.password_error = false;
              }else{
                  this.password_error = true;
                  this.wrong = true
              }
          }

      },
      logout() {
        this.userLogOut()
            .then(() => {})
            .catch((error) => {
                window.location.reload();
                reject(error);
            });
            this.$store.commit('resetAllStorage')
            this.$router.push(`/login?redirect=${this.$route.fullPath}`);
      },

    }
}
</script>
<style scoped>
  .login__left {
    background: #1e1e2d;
    height: 100vh;
  }
  .login__right {
    background: #151521;
    height: 100vh;
    display: flex;
    justify-content: center;
  }
  .login__center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .login {
    font-family: Nunito,sans-serif;
    line-height: 1.6;
    width: 100%;
    height: 100%;
    left: 0;
    position: fixed;
    z-index: 2003;
  }
  .login__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 60px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .sign__title {
    text-align: center;
  }
  .log__out span {
    color: #fff;
  }
  .sign__title h5 {
    font-size: 22.75px;
    font-weight: 400;
  }
  .sign__title span {
    font-size: 16.25px;
    font-weight: 500;
    color: #474761;
  }
  .sign__title span a {
    color: #0d67c9;
    font-weight: 600;
  }
  .login__title img {
    width: 150px;
  }
  .login__title h4{
    font-size: 29.25px;
    font-weight: 500;
    color: #fff;
    margin: 10px 0;
  }
  .login__title span {
    font-size: 16.25px;
    font-weight: 400;
    color: #fff;
  }
  .login__box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .login__footer {
    display: flex;
    justify-content: center;
  }
  .login__footer img {
    width: 80%;
  }
  .login__input {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    height: 72px;
    position: relative;
  }
  .login__size {
    width: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .sign__title h5 {
    font-size: 18.75px;
    color: #fff;
  }
  .login__input input {
    background-color: #323248;
    border: 1px solid #323248;
    color: #92929f;
    font-size: 1.95rem;
    outline: none;
    box-shadow: none;
    padding: 0 10px;
    height: 45px;
    margin-top: 5px;
    border-radius: 0.475rem !important;
    transition: all 120ms ease-out;
  }
  .login__input span {
    color: #fff;
    font-size: 13.95px;
    font-weight: 500;
    position: absolute;
    bottom: 35px;
    margin-left: 10px;
    transition: all 120ms ease-out;
  }
  .login__forgot {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .login__forgot a {
    color: #0d67c9;
    font-weight: 600;
    text-decoration: none;
  }
  .login__submit {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
  }
  .login__submit a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #4ac9ff;
    color: #fff;
    font-weight: 500;
    font-size: 18.95px;
    text-align: center;
    border: 1px solid #4ac9ff;
    border-radius: 0.55rem;
    height: 45px;
    cursor: pointer;
  }
  .active__text {
    margin-top: -20px;
    bottom: 75px !important;
    margin-left: 0px !important;
    color: #0d67c9 !important;
  }
  .active__input {
    border: 1px solid #0d67c9 !important;
  }
  .log__out {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    margin-top: 20px;
  }
  .log__outtext {
    cursor: pointer;
    color: #0d67c9;
    font-weight: 600;
  }
  .wrong__paswword {
    display: flex;
    justify-content: center;
    color: red;
  }
  .wrong__input input {
    border: 1px solid red !important;
  }
  .wrong__text {
    color: red !important;
  }
</style>
