<template>
  <div>
    <el-select :class="mode ? 'input__day' : 'input__night'" :value="selected" @input="dispatch" :placeholder="placeholder || $t('message.vacancy')" filterable clearable :multiple="multiple"
      :size="size" class="d-block">
      <el-option v-for="(vacancy,index) in vacancies" :key="'vacancies-' + index" :label="vacancy.name" :value="vacancy.id"></el-option>
    </el-select>
  </div>
</template>
<script>
  import {mapGetters, mapActions} from "vuex";
  import { i18n  } from '@/utils/i18n';
  export default {
    props: {
        size: {
            default: 'small'
        },
        placeholder: {
            default: null,
        },
        id:{
          default: null
        },
        multiple:{
            type: Boolean,
            default: false,
        }
    },
     watch:{
      id:{
        handler: function() {                
            this.selected = this.id
        },
        immediate: true
      }
    },
    data() {
      return {
        selected: null,
      }
    },
    mounted() {
      if (this.vacancies && this.vacancies.length === 0) this.updateInventory()
    },
    computed: {
      ...mapGetters({
        vacancies: 'vacancy/inventory',
        mode:'MODE'
      })
    },
    methods: {
      ...mapActions({
        updateInventory: 'vacancy/inventory'
      }),
      dispatch(e) {
        this.$emit('input', e)
        this.selected = e
      }
    },
  }

</script>
