import request from '@/utils/request'

export function index(params) {
  return request({
    url: '/user_activities',
    method: 'get',
    params: params
  })
}


export function inventory() {
  return request({
    url: '/user_activity/get_models',
    method: 'get',
  })
}

export function show(id) {
  return request({
    url: `/user_activities/${id}`,
    method: 'get'
  })
}
