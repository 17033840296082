import { i18n } from "@/utils/i18n";
export const columns = {
  id: {
    show: true,
    title: i18n.t("message.n"),
    sortable: true,
    column: "id"
  },
  name: {
    show: true,
    title: i18n.t("message.nameFull"),
    sortable: true,
    column: "name"
  },
  documentType: {
    show: true,
    title: i18n.t("message.documentType"),
    sortable: true,
    column: "documentType"
  },
  branch: {
    show: true,
    title: i18n.t("message.branch"),
    sortable: true,
    column: "branch"
  },
  manager: {
    show: true,
    title: i18n.t("message.responsible"),
    sortable: true,
    column: "manager"
  },
  file: {
    show: true,
    title: i18n.t("message.file"),
    sortable: true,
    column: "file"
  },
  created_at: {
    show: true,
    title: i18n.t("message.created_at"),
    sortable: true,
    column: "created_at"
  },
  updated_at: {
    show: false,
    title: i18n.t("message.updated_at"),
    sortable: true,
    column: "updated_at"
  },
  settings: {
    show: true,
    title: i18n.t("message.settings"),
    sortable: false,
    column: "settings"
  }
};
