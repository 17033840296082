export const model = {
  id: null,
  coefficients:[
    {
      coefficient: null
    },
  ],
  nationality: '',
  created_at: '',
  updated_at: '',
};
