import request from '@/utils/request'

export function getCard(staff_id) {
    return request({
        url: `/staffs/staff_transaction/get_card/${staff_id}`,
        method: 'get'
    })
}
export function getPenalty(staff_id) {
    return request({
        url: `/staffs/staff_transaction/get_penalty/${staff_id}`,
        method: 'get'
    })
}
export function getBonus(staff_id) {
    return request({
        url: `/staffs/staff_transaction/get_bonus/${staff_id}`,
        method: 'get'
    })
}
export function getAdvance(params) {
  return request({
      url: `/staffs/staff_transaction/get_advance`,
      method: 'get',
      params

  })
}
export function getSalary(staff_id) {
    return request({
        url: `/staffs/staff_transaction/get_salary/${staff_id}`,
        method: 'get'
    })
}
export function getSalaryToWorkHours(params) {
  return request({
      url: `/staffs/staff_transaction/get_salary_to_works_hour/${params.staff_id}`,
      method: 'get',
      params

  })
}
export function getGraphic(staff_id) {
    return request({
        url: `/staffs/staff_transaction/get_graphic/${staff_id}`,
        method: 'get'
    })
}
export function getTransaction(params) {
    return request({
        url: `/staffs/staff_transaction/get_transaction/${params.staff_id}`,
        method: 'get',
        params
    })
}


