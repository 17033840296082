import { i18n } from '@/utils/i18n';

export const columns = {
    id : {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    device_name : {
        show: true,
        title: i18n.t('message.nameGiven'),
        sortable: true,
        column: 'device_name'
    },
    serial_number : {
        show: true,
        title: i18n.t('message.serial_number'),
        sortable: true,
        column: 'serial_number'
    },
    ip_address : {
        show: true,
        title: i18n.t('message.ip_address'),
        sortable: true,
        column: 'ip_address'
    },
    port : {
      show: true,
      title: i18n.t('message.port'),
      sortable: true,
      column: 'port'
    },
    device_type_id : {
        show: true,
        title: i18n.t('message.deviceType'),
        sortable: true,
        column: 'device_type_id'
    },
    security_location_id : {
      show: true,
      title: i18n.t('message.security_location'),
      sortable: true,
      column: 'security_location_id'
    },
    status_id : {
        show: true,
        title: i18n.t('message.status'),
        sortable: true,
        column: 'status_id'
    },
    created_at : {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at : {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },   
    settings : {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    },

};
