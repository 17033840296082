import { index, store, getLang } from '@/api/settings/settingApp';
import { setNumberMoneyProduct, setNumberMoneyMaterial, setNumberQuantityProduct, setNumberQuantityMaterial } from "@/utils/local_storage";

export const actions = {

    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params).then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data).then(res => {
                const { number_money_product, number_money_material, number_quantity_product, number_quantity_material } = res.data.result.data
                resolve(res.data.result)
            }).catch(err => {
                // reject(err.response.data)
            })
        })
    },

    getLang() {
        return new Promise((resolve, reject) => {
            getLang().then(res => {
                resolve(res.data.result)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    },
};
