import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "./properties/sort";
import { filter } from "./properties/filter";
import store from '@/store/index'
import { model } from "./properties/model";
import { i18n } from '@/utils/i18n';
export const mutations = {
    SET_LIST: (state, list) => (state.list = list),
    SET_REASON: (state, reasons) => {              
      state.reasons = reasons;        
    },
    SET_INVENTORY: (state, statuses) => (state.inventory = statuses),
    SET_SORT: (state, sort) => (state.sort = sort),
    SET_FILTER: (state, filter) => (state.filter = filter),
    SET_PAGINATION: (state, pagination) => (state.pagination = pagination),
    UPDATE_PAGINATION: (state, pagination) => {
        state.pagination[pagination.key] = pagination.value
    },
    UPDATE_PAGINATION_ATTENDANCE: (state, pagination) => {
        state.paginationAttendance[pagination.key] = pagination.value
    },
    UPDATE_COLUMN: (state, obj) => {
        state.columns[obj.key].show = obj.value
    },
    UPDATE_SORT: (state, sort) => {
        state.sort[sort.column] = sort.order
    },
    SET_MODEL: (state, status) => {
       state.model=status;
    },
    EMPTY_MODEL: (state) => {
        state.model = JSON.parse(JSON.stringify(model));       
    },
    REFRESH: (state) => {
        state.filter = JSON.parse(JSON.stringify(filter));
        state.sort = JSON.parse(JSON.stringify(sort));
        state.pagination = JSON.parse(JSON.stringify(pagination));
        state.paginationAttendance = JSON.parse(JSON.stringify(pagination))
    }

};
