import Layout from '@/layouts/EmptyLayout'
export default [{
    path: '/company-info',
    name: 'companyInfo',
    props: true,
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/companyInfo/index')
    
  }]
