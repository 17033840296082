import request from '@/utils/request'


export function application(params) {
    return request({
        url: '/staffs/application',
        method: 'get',
        params: params
    })
}

export function downloadAppFile(data) {
  return request({
      url: `/staffs/print-application/${data.id}`,
      method: 'get',
      responseType:'blob'
  })
}
