import request from '@/utils/request'

export function staff_list_check_in(params) {
  return request({
      url: `/dashboard/staffs/check_in`,
      method: 'get',
      params
  })
}

export function staff_list_not_check_in(params) {
  return request({
      url: `/dashboard/staffs/not_check_in`,
      method: 'get',
      params
  })
}
export function come_to_work(params) {
  return request({
      url: `/dashboard/staffs/come-to-work`,
      method: 'get',
      params
  })
}
export function not_come_to_work(params) {
  return request({
      url: `/dashboard/staffs/not-come-to-work`,
      method: 'get',
      params
  })
}
export function late_to_work(params) {
  return request({
      url: `/dashboard/staffs/late-to-work`,
      method: 'get',
      params
  })
}
export function accepted_take_assent(params) {
  return request({
      url: `/dashboard/staffs/accept-take-assent`,
      method: 'get',
      params
  })
}
export function sick_leave(params) {
  return request({
      url: `/dashboard/staffs/sick-leaves`,
      method: 'get',
      params
  })
}
export function on_vacation(params) {
  return request({
      url: `/dashboard/staffs/on-vacation`,
      method: 'get',
      params
  })
}



export function not_connect_telegram(params) {
  return request({
      url: `/dashboard/staffs/no-telegram-staff`,
      method: 'get',
      params
  })
}
export function waiting_answer_telegram(params) {
  return request({
      url: `/dashboard/staffs/waiting-answer`,
      method: 'get',
      params
  })
}

export function getTmsInfo(id) {
  return request({
      url: `/kpp/get-tms-info/${id}`,
      method: 'get'
  })
}
export function getMostToiletStaffInfo(id) {
  return request({
      url: `/dashboard/toilet-attandance/${id}`,
      method: 'get'
  })
}
export function getHaveSalaryStaffs(params) {
  return request({
      url: `/dashboard/staffs/have-salary`,
      method: 'get',
      params
  })
}
export function getDoesntHaveSalayStaffs(params) {
  return request({
      url: `/dashboard/staffs/does-not-have-salary`,
      method: 'get',
      params
  })
}
export function getMoodStaffs(params) {
  return request({
      url: `/dashboard/staffs/by-mood`,
      method: 'get',
      params
  })
}
export function come_sender(params) {
  return request({
      url: `/dashboard/staffs/comeing-answer-telegram`,
      method: 'get',
      params
  })
}

export function not_come_sender(params) {
  return request({
      url: `/dashboard/staffs/not-comeing-answer-telegram`,
      method: 'get',
      params
  })
}
