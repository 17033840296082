import { state } from "../structure/state";

export const getters = {
  list: state => state.list,
  inventory: state => state.inventory,
  model: state => state.model,
  rules: state => state.rules,
  columns: state => state.columns,
  filter: state => state.filter,
  pagination: state => state.pagination,
  sort: state => state.sort,
  staff_contact: state=>state.staff_contact,
  staff_educations:state=>state.staff_educations,
  staff_skills:state=>state.staff_skills,
  staff_children:state=>state.staff_children,
  staff_inventory:state=>state.staff_inventory,
  inventories:state=>state.inventories,
  staff_work_places:state=>state.staff_work_places,
  staff_work_history:state=>state.staff_work_history,
  staff_client_certificates:state=>state.staff_client_certificates,
  staff_client_health_information:state=>state.staff_client_health_information,
  staff_client_requirement:state=>state.staff_client_requirement,
  staff_status_and_reason: state=>state.staff_status_and_reason,
  work_type:state=>state.work_type,
  staff_documents:state=>state.staff_documents,
  staff_languages:state=>state.staff_languages,
  staff_salaries: state=>state.staff_salaries,
  staff_cards:state=>state.staff_cards,
  staff_bonuses:state=>state.staff_bonuses,
  staff_penalties:state=>state.staff_penalties,
  staff_documents_five: state=>state.staff_documents_five
};
