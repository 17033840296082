export const rules = {
  staff_id: [{
    required: true,
    message: 'Пожалуйста, введите название ',
    trigger: 'change'
  }],
  type: [{
    required: true,
    message: 'Пожалуйста, введите название ',
    trigger: 'change'
  }],
  transport_id: [{
    required: true,
    message: 'Пожалуйста, введите название ',
    trigger: 'change'
  }],
  left_time: [{
    required: true,
    message: 'Пожалуйста, введите название ',
    trigger: 'change'
  }],
  left_date: [{
    required: true,
    message: 'Пожалуйста, введите название ',
    trigger: 'change'
  }],
  arrival_time: [{
    required: true,
    message: 'Пожалуйста, введите название ',
    trigger: 'change'
  }],
  arrival_date: [{
    required: true,
    message: 'Пожалуйста, введите название ',
    trigger: 'change'
  }],
  district_id: [{
    required: true,
    message: 'Пожалуйста, введите название ',
    trigger: 'change'
  }],
  left_employee: [{
    required: true,
    message: 'Пожалуйста, введите название ',
    trigger: 'change'
  }],
  expected_employee: [{
    required: true,
    message: 'Пожалуйста, введите название ',
    trigger: 'change'
  }],
};
