<template>
  <el-select
    :value="selected"
    @input="dispatch"
    :placeholder="placeholder || $t('message.status')"
    filterable
    clearable
    :size="size"
    class="d-block"
    :class="mode ? 'input__day' : 'input__night'"
  >
    <el-option
      v-for="(status, index) in statuses"
      :key="'status-' + index"
      :label="$t('message.'+status.name)"
      :value="status.id"
    >
    </el-option>
  </el-select>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "status-card",
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    table_name: {
      default: null,
      type: String,
      required: true,
    },
    id: {
      default: null,
    }
    
  },
  mounted() {
    if (this.statuses){
        this.fetchStatuses();
    }
  },
  data() {
    return {
      selected: null,
    };
  },
  watch: {
    id: {
      handler: function () {
        this.selected = this.id;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      statuses: "statuscard/inventory",
      mode:'MODE'
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "statuscard/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
   
    fetchStatuses() {
      const query = { table_name:  this.table_name };
      this.updateInventory(query)
        .then((res) => {                       
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },
  },
};
</script>
