import {
  i18n
} from '@/utils/i18n';

export const columns = {
  id: {
    show: true,
    title: i18n.t('message.n'),
    sortable: true,
    column: 'id'
  },
  staff_id: {
    show: true,
    title: i18n.t('message.staff'),
    sortable: true,
    column: 'staff_id'
  },
  branch: {
    show: true,
    title: i18n.t('message.branch'),
    sortable: true,
    column: 'branch'
  },
  lay_off_date: {
    show: true,
    title: i18n.t('message.lay_off_date'),
    sortable: true,
    column: 'lay_off_date'
  },
  comment: {
    show: true,
    title: i18n.t('message.comment'),
    sortable: true,
    column: 'comment'
  },
  created_at: {
    show: true,
    title: i18n.t('message.created_at'),
    sortable: true,
    column: 'created_at'
  },
  updated_at: {
    show: true,
    title: i18n.t('message.updated_at'),
    sortable: true,
    column: 'updated_at'
  },
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: 'settings'
  }

};
