import { columns } from './properties/columns'
import { filter } from './properties/filter'
import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { rules } from "./properties/rules";
import { model } from "./properties/model";
export const state = {
    list: [],
    inventory: [],
    model: JSON.parse(JSON.stringify(model)),
    columns: columns,
    filter: filter,
    pagination: JSON.parse(JSON.stringify(pagination)),
    sort: JSON.parse(JSON.stringify(sort)),   
    rules: rules,
    staff_contact: {},
    staff_educations: [],
    staff_skills: [],
    staff_children:[],
    staff_inventory:[],
    inventories:[],
    staff_work_places: [],
    staff_work_history: [],
    staff_client_certificates: [],
    staff_client_health_information: [],
    staff_client_requirement: [],
    staff_status_and_reason:{},
    work_type: {},
    staff_documents:[],
    staff_languages:[],
    staff_salaries:[],
    staff_cards:[],
    staff_bonuses:[],
    staff_penalties:[],
    staff_documents_five:[]
};
