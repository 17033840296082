export const getters = {
  list: state => state.list,
  reasons: state => state.reasons,
  inventory: state => state.inventory,
  model: state => state.model,
  staff: state => state.staff,
  staff_contact: state=>state.staff_contact,
  staff_client_requirement:state=>state.staff_client_requirement,
  rules: state => state.rules,
  columns: state => state.columns,
  filter: state => state.filter,
  pagination: state => state.pagination,
  paginationAttendance: state => state.paginationAttendance,
  sort: state => state.sort
};
