export const rules = {
    name: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    status_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    work_type_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    gender_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    phone_number: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    last_name: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    passport_number: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    passport_issued_by: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    passport_valid_until: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    hiring_date: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    department_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    position_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
};
