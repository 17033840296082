import request from '@/utils/request'

export function index(params) {
  return request({
      url: '/recruitments/vacancies',
      method: 'get',
      params: params
  })
}


export function inventory(params) {
  return request({
      url: '/recruitments/vacancy/inventory',
      method: 'get',
      params
  })
}

export function show(id) {
  return request({
      url: `/recruitments/vacancies/${id}`,
      method: 'get'
  })
}

export function store(data) {
  return request({
      url: '/recruitments/vacancies',
      method: 'post',
      data
  })
}

export function update(data) {
  return request({
      url: `/recruitments/vacancies/${data.id}`,
      method: 'put',
      data
  })
}

export function destroy(id) {
  return request({
      url: `/recruitments/vacancies/${id}`,
      method: 'delete',
  })
}

export function candidate(object) {
    return request({
        url: `/recruitments/vacancy/candidate/${object.vacancy_id}`,
        method: 'get',
        params: object.params
    })
  }
  export function updateCandidate(object) {
    return request({
        url: `/recruitments/vacancy/candidate-update/${object.vacancy_id}`,
        method: 'post',
        params: object
    })
  }
  export function getSocialChart(id) {
    return request({
        url: `/recruitments/vacancy/get-social-chart/${id}`, 
        method: 'get',
    })
  }
  export function getSteppChart(id) {
    return request({
        url: `/recruitments/vacancy/get-stepp-chart/${id}`, 
        method: 'get',
    })
  }
  export function getSteppChartByCount(id) {
    return request({
        url: `/recruitments/vacancy/get-stepp-chart-by-count/${id}`, 
        method: 'get',
    })
  }
