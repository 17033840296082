import { columns } from './properties/columns'
import { filter } from './properties/filter'
import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";
import { rules } from "./properties/rules";
import { model } from "./properties/model";

export const state = {
    list: [],
    inventory: [],    
    model: JSON.parse(JSON.stringify(model)),
    columns: columns,
    filter: filter,
    pagination: {
        page: 1,
        per_page: 100,
        total: 100,
    },
    sort: {
        column: 'id',
        order: 'ASC'
    },   
    rules: rules,   
};
