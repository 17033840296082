export const rules = {
    guest_id: [{
        required: true,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    }, ],
    staff_id: [{
        required: true,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    }, ],
    visiting_date: [{
        required: true,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    }, ],
    visiting_time: [{
        required: true,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    }, ],
    guest_phone_number_id: [{
        required: true,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    }, ],
    guest_coming_reason_id: [{
        required: true,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    }, ],
    guest_company_id: [{
        required: true,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    }, ],
    guest_car_id: [{
        required: false,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    }, ],
    company_id: [{
      required: true,
      message: 'Пожалуйста, введите название ',
      trigger: 'change'
  }, ],

};
