import { advanceSalary, salaryReports, addSalary } from "@/api/staff/askAssent";

export const actions = {
  advanceSalary({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      advanceSalary(params)
        .then(res => {
          commit("SET_LIST", res.data.result.data.advance_salaries);
          commit("UPDATE_PAGINATION", {
            key: "total",
            value: res.data.result.data.pagination.total
          });
          resolve(res);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  },
  salaryReports({ commit }, params = {}) {
    return new Promise((resolve, reject) => {
      salaryReports(params)
        .then(res => {
          commit("SET_REPORT", res.data.result.data.salary_reports);
          // commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
          resolve(res);
        })
        .catch(err => {
          reject(err.response.data);
        });
    });
  },
  addSalary({ commit }, data) {
    return new Promise((resolve, reject) => {
      addSalary(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  empty({ commit }) {
    return new Promise((resolve, reject) => {
      commit("EMPTY_MODEL");
      resolve();
    });
  },
  updateSort({ commit }, sort) {
    commit("SET_SORT", sort);
  },

  updateFilter({ commit }, filter) {
    commit("SET_FILTER", JSON.parse(JSON.stringify(filter)));
  },

  updateColumn({ commit }, obj) {
    commit("UPDATE_COLUMN", obj);
  },
  updatePagination({ commit }, pagination) {
    commit("UPDATE_PAGINATION", pagination);
  },
  updatePaginationAttendance({ commit }, pagination) {
    commit("UPDATE_PAGINATION_ATTENDANCE", pagination);
  },
  refreshData({ commit }) {
    return new Promise((resolve, reject) => {
      commit("REFRESH");
      resolve();
    });
  }
};
