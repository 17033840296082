export const rules = {
    driver: [{
        required: true,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    }],
    transport_id: [{
        required: false,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    }],
    reason_id: [{
        required: true,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    }],
    comment: [{
      required: true,
      message: 'Пожалуйста, введите название ',
      trigger: 'change'
    }],
    left_time: [{
        required: true,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    }],
    left_date: [{
        required: true,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    }],
    arrival_time: [{
        required: true,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    }],
    arrival_date: [{
        required: true,
        message: 'Пожалуйста, введите название ',
        trigger: 'change'
    }],
};
