export const model = {
  id: null,
  name: '', 
  branch_id: '',
  
  company_id: '',
  created_at: '',
  updated_at: '',
  
};
