import { i18n} from '@/utils/i18n';  

export const columns = {
    id: {
        show: true,
        title: i18n.t('message.n'),
        sortable: true,
        column: 'id'
    },
    staff_full_name: {
        show: true,
        title: i18n.t('message.nameFull'),
        sortable: true,
        column: 'staff_full_name'
    },
    department: {
        show: true,
        title: i18n.t('message.department'),
        sortable: true,
        column: 'department'
    },
    branch: {
        show: false,
        title: i18n.t('message.branch'),
        sortable: true,
        column: 'branch'
    },
    company: {
        show: false,
        title: i18n.t('message.company'),
        sortable: true,
        column: 'company'
    },
    punch_time: {
        show: true,
        title: i18n.t('message.time'),
        sortable: true,
        column: 'punch_time'
    },
    punch_state: {
        show: true,
        title: i18n.t('message.attendance_type'),
        sortable: true,
        column: 'punch_state'
    },
    img: {
        show: true,
        title: i18n.t('message.img'),
        sortable: true,
        column: 'img'
    },
    // verify_type: {
    //     show: false,
    //     title: i18n.t('message.verify_type'),
    //     sortable: true,
    //     column: 'verify_type'
    // },
    device: {
        show: true,
        title: i18n.t('message.device'),
        sortable: true,
        column: 'device'
    },
    created_at: {
        show: true,
        title: i18n.t('message.created_at'),
        sortable: true,
        column: 'created_at'
    },
    updated_at: {
        show: true,
        title: i18n.t('message.updated_at'),
        sortable: true,
        column: 'updated_at'
    },
    settings: {
        show: true,
        title: i18n.t('message.settings'),
        sortable: false,
        column: 'settings'
    }

};
