import request from "@/utils/request";

export function index(params) {
  return request({
    url: "/settings/document-types",
    method: "get",
    params: params
  });
}

export function inventory(params) {
  return request({
    url: "/settings/document-type/inventory",
    method: "get",
    params
  });
}

export function show(id) {
  return request({
    url: `/settings/document-types/${id}`,
    method: "get"
  });
}

export function store(data) {
  return request({
    url: "/settings/document-types",
    method: "post",
    data
  });
}

export function update(data) {
  return request({
    url: `/settings/document-types/${data.id}`,
    method: "put",
    data
  });
}

export function destroy(id) {
  return request({
    url: `/settings/document-types/${id}`,
    method: "delete"
  });
}
