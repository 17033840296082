import {
  i18n
} from '@/utils/i18n';
export const columns = {
  id: {
    show: true,
    title: i18n.t('message.n'),
    sortable: true,
    column: 'id'
  },
  type: {
    show: true,
    title: i18n.t('message.type'),
    sortable: true,
    column: 'type'
  },
  car_number: {
    show: true,
    title: i18n.t('message.car_number'),
    sortable: true,
    column: 'car_number'
  },
  car_model: {
    show: false,
    title: i18n.t('message.car_model'),
    sortable: true,
    column: 'car_model'
  },
  car_color: {
    show: false,
    title: i18n.t('message.car_color'),
    sortable: true,
    column: 'car_color'
  },
  driver: {
    show: true,
    title: i18n.t('message.car_driver'),
    sortable: true,
    column: 'driver'
  },
  left_date_time: {
    show: true,
    title: i18n.t('message.left_date_time'),
    sortable: true,
    column: 'left_date_time'
  },


  arrival_date_time: {
    show: true,
    title: i18n.t('message.arrival_date_time'),
    sortable: true,
    column: 'arrival_date_time'
  },

  total_spent_time: {
    show: true,
    title: i18n.t('message.difference'),
    sortable: true,
    column: 'total_spent_time'
  },
  expected_employee: {
    show: true,
    title: i18n.t('message.expected_emp'),
    sortable: true,
    column: 'expected_employee'
  },
  factual_employee: {
    show: true,
    title: i18n.t('message.factual_emp'),
    sortable: true,
    column: 'factual_employee'
  },
  left_employee: {
    show: true,
    title: i18n.t('message.exit'),
    sortable: true,
    column: 'left_employee'
  },
  district: {
    show: true,
    title: i18n.t('message.district'),
    sortable: true,
    column: 'district'
  },
  created_at: {
    show: false,
    title: i18n.t('message.created_at'),
    sortable: true,
    column: 'created_at'
  },
  updated_at: {
    show: false,
    title: i18n.t('message.updated_at'),
    sortable: true,
    column: 'updated_at'
  },
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: 'settings'
  }
};
