export const rules = {
    name: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    telegram: [
      { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    status_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    graphic_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    work_type_id: [
        { required: false, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    gender_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    phone_number: [
        { required: false, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    last_name: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    passport_number: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    passport_issued_by: [
        { required: false, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    passport_valid_until: [
        { required: false, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    hiring_date: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    company_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    nation_id: [
        { required: false, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    position_id: [
        { required: true, message: 'Пожалуйста, введите название ', trigger: 'change' },
    ],
    card_number: [
      { required: true, message: 'Пожалуйста, введите название ', trigger: 'blur' },
    ],
    expiry_date: [
      { required: true, message: 'Пожалуйста, введите название ', trigger: 'blur' },
    ],
    currency_id: [
      { required: true, message: 'Пожалуйста, введите название ', trigger: 'blur' },
    ],
    official_amount: [
      { required: true, message: 'Пожалуйста, введите название ', trigger: 'blur' },
    ],
    unofficial_amount: [
      { required: true, message: 'Пожалуйста, введите название ', trigger: 'blur' },
    ],
    date_of_start: [
      { required: true, message: 'Пожалуйста, введите название ', trigger: 'blur' },
    ],
    type: [
      { required: true, message: 'Пожалуйста, введите название ', trigger: 'blur' },
    ],
    staff_work_type_id: [
      { required: false, message: 'Пожалуйста, введите название ', trigger: 'blur' },
    ],
};
