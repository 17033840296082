export const getters = {
  list: state => state.list,
  attendance_report: state => state.attendance_report,
  staff: state => state.staff,
  attendance_info: state => state.attendance_info,
  attendance_work_info: state => state.attendance_work_info,
  inventory: state => state.inventory,
  model: state => state.model,
  rules: state => state.rules,
  columns: state => state.columns,
  filter: state => state.filter,
  pagination: state => state.pagination,
  sort: state => state.sort 
};
