import {
  i18n
} from '@/utils/i18n';

export const columns = {
  id: {
    show: true,
    title: i18n.t('message.n'),
    sortable: true,
    column: 'id'
  },
  staff_id: {
    show: true,
    title: i18n.t('message.staff'),
    sortable: true,
    column: 'staff_id'
  },
  manager_id: {
    show: true,
    title: i18n.t('message.manager'),
    sortable: true,
    column: 'manager_id'
  },
  reason: {
    show: true,
    title: i18n.t('message.reason'),
    sortable: true,
    column: 'reason'
  },

  comment : {
    show: true,
    title: i18n.t('message.comment'),
    sortable: true,
    column: 'comment'
},
  status: {
    show: true,
    title: i18n.t('message.status'),
    sortable: true,
    column: 'status'
  },
  visit_date: {
    show: true,
    title: i18n.t('message.visit_date'),
    sortable: true,
    column: 'visit_date'
  },
  left_date: {
    show: true,
    title: i18n.t('message.left_date'),
    sortable: true,
    column: 'left_date'
  },
  settings: {
    show: true,
    title: i18n.t('message.settings'),
    sortable: false,
    column: 'settings'
  }

};
