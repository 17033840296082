import request from '@/utils/request'

export function index(params) {
  return request({
      url: '/quiz/telegram-quizzes',
      method: 'get',
      params: params
  })
}

export function show(id) {
  return request({
      url: `/quiz/telegram-quizzes/${id}`,
      method: 'get'
  })
}
export function getQuizInfo(id) {
  return request({
      url: `/quiz/telegram-quiz-info/${id}`,
      method: 'get'
  })
}
export function destroy(id) {
  return request({
      url: `/quiz/telegram-quizzes/${id}`,
      method: 'delete',
  })
}
