import { inventory } from "@/api/staff/familyStatus";
export const actions = {
   
    
    inventory({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            inventory(params).then(res => {                
                commit("SET_INVENTORY", res.data.result.data.family_statuses);
                resolve(res)
            }).catch(err => {
                reject(err.response.data)
            })
        })
    }
};
