export const getters = {  
  cards: state => state.cards,
  penalties: state=>state.penalties,
  bonuses: state=>state.bonuses,
  advances: state=>state.advances,
  salary:state=>state.salary,
  work_salary:state=>state.work_salary,
  graphic:state=>state.graphic,
  transactions:state=>state.transactions,
  payment_months:state=>state.payment_months,
  start_and_end_dates:state=>state.start_and_end_dates
};
